import { TuiIcon } from '@taiga-ui/core';
import { TuiTabs } from '@taiga-ui/kit';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { DepartmentAdministrationTabs } from 'src/app/shared/models/enums/department-administration-tabs.enum';
import { DepartmentUsersAdminService } from '../department-users-admin/department-users-admin.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DepartmentAdminService } from './department-admin.service';
import { Observable } from 'rxjs';
import { Department } from 'src/app/shared/models/base/department';
import { ClientTitleHeaderComponent } from '../../client/client-title-header/client-title-header.component';
import { MenuEntry } from 'src/app/shared/models/base/menu-entry';
import { Client } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Component({
  selector: 'pxc-department-admin',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiTabs,
    TuiIcon,
    RouterModule,
    ClientTitleHeaderComponent,
  ],
  providers: [
    DepartmentUsersAdminService,
    DepartmentAdminService,
  ],
  templateUrl: './department-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentAdminComponent implements OnInit {

  department$: Observable<Department>;
  client$: Observable<Client>;

  tabs: MenuEntry[] = [
    {
      title: 'Utilisateurs',
      icon: '@tui.user',
      link: DepartmentAdministrationTabs.USERS,
    },
    {
      title: 'Mots clés',
      icon: '@tui.tag',
      link: DepartmentAdministrationTabs.LABELS,
    },
  ];

  readonly departmentAdministrationService = inject(DepartmentAdminService);

  ngOnInit(): void {
    this.department$ = this.departmentAdministrationService.loadDepartment$();
    this.client$ = this.departmentAdministrationService.loadClient$();
  }

  updateDepartmentCollaboration(isOpen: boolean): void {
    this.departmentAdministrationService.updateCollaboration(isOpen);
  }

}
