import {
  AfterViewInit,
  ChangeDetectionStrategy, Component, HostListener, Inject, Input, QueryList, ViewChild, ViewChildren, ViewContainerRef,
} from '@angular/core';
import { WidgetService } from '../../shared/widget/widget.service';
import { GalleryModel } from '../gallery-model';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { TuiButton, TuiDialogContext } from '@taiga-ui/core';
import { GallerySourceType } from 'src/app/shared/models/enums/gallery-source-type.enum';
import { GalleryMode } from 'src/app/shared/models/enums/gallery-mode.enum';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@modules/shared/shared.module';
import { GalleryThumbnailListComponent } from '../gallery-thumbnail-list/gallery-thumbnail-list.component';

@Component({
  selector: 'pxc-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiButton,
    GalleryThumbnailListComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent implements AfterViewInit {

  @ViewChild('insertionAnchor', { read: ViewContainerRef }) widgetHost!: ViewContainerRef;
  @ViewChildren('insertionAnchors', { read: ViewContainerRef }) widgetHosts!: QueryList<ViewContainerRef>;

  @Input() galleryModel: GalleryModel = this.context.data;

  gMode = GalleryMode;
  gSourceType = GallerySourceType;

  pictureLoadedIdx: boolean[] = [];

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, GalleryModel>,
    private readonly widgetService: WidgetService,
  ) { }

  @HostListener('document:keyup', ['$event'])
  keyPress(event: KeyboardEvent): void {

    if (!this.galleryModel.config.keyboardShortcuts) {
      return;
    }

    switch (event.key.toLowerCase()) {

      case 'escape': {
        this.close();
        break;
      }

      case 'arrowleft': {
        this.galleryModel.navPrev();
        break;
      }

      case 'arrowright': {
        this.galleryModel.navNext();
        break;
      }

      default: {
        break;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.galleryModel.fixedWidget) {
      this.widgetService.loadWidget(
        this.widgetHost,
        this.galleryModel.fixedWidget,
      );
    }
    if (this.galleryModel.imageWidgets?.length) {
      this.widgetHosts.forEach((viewContainerRef, index) => {
        this.widgetService.loadWidget(viewContainerRef, this.galleryModel.imageWidgets[index]);
      });
    }
  }

  onWheel(event: { currentTarget: HTMLInputElement; deltaY: number; ctrlKey: boolean }): void {

    if (event.ctrlKey) {
      return;
    }

    event.currentTarget.scrollLeft += event.deltaY;
  }

  onPictureLoad(idx: number): void {
    this.pictureLoadedIdx[idx] = true;
  }

  onPictureError(idx: number): void {
    this.galleryModel.onImageLoadingError(idx);
    this.pictureLoadedIdx[idx] = false;
  }

  isInLazyLoadRange(idx: number, length: number): boolean {
    return [
      this.galleryModel.getPrevIdx(this.galleryModel.displayIdx, length),
      this.galleryModel.displayIdx,
      this.galleryModel.getNextIdx(this.galleryModel.displayIdx, length),
    ].includes(idx);
  }

  close(): void {
    this.context.completeWith();
  }

}
