import { inject } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogoutStrategyService } from 'src/app/services/logout-strategy.service';

export const unauthorizedInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const logoutStrategyService = inject(LogoutStrategyService);
  return next(req).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
          return throwError(() => err);
        }
        logoutStrategyService.logout('/');
      }
    }),
  );
};
