/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, first, map } from 'rxjs';
import { ListItem } from 'src/app/shared/models/helpers/list-item';
import { selectClients } from 'src/app/shared/store/clients/clients.selectors';
import { selectClientDepartments } from 'src/app/shared/store/departments/departments.selectors';
import { selectWriteAccessClientIds } from 'src/app/shared/store/licenses/licenses.selectors';
import { DepartmentUserService } from '../department-user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientData } from 'src/app/shared/models/base/client-data';
import { CommonModule } from '@angular/common';
import { ClientPickerComponent } from '../../client/client-picker/client-picker.component';
import { ListPickerComponent } from '@modules/shared/list-picker/list-picker.component';
import { departmentsPluralMapping } from '@shared/utils/plural-mappings';

@Component({
  selector: 'pxc-department-picker',
  templateUrl: './department-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ClientPickerComponent,
    ListPickerComponent,
  ],
})
export class DepartmentPickerComponent implements OnChanges {

  @Input() clientCode: string;
  @Input() departmentIds: number[] = [];
  @Input() isClientChoiceDisabled = false;

  @Output() clientCodeChange = new EventEmitter<string>();
  @Output() departmentIdsChange = new EventEmitter<number[]>();

  private readonly store = inject(Store);
  private readonly departmentUserService = inject(DepartmentUserService);
  private readonly authenticationService = inject(AuthenticationService);

  pluralMapping = departmentsPluralMapping;

  clients$ = combineLatest([
    this.store.select(selectClients),
    this.store.select(selectWriteAccessClientIds),
  ]).pipe(
    map(([clients, writeAccessIds]) => Object.values(clients)
      .filter((client) => writeAccessIds.includes(client.id))
      .reduce((acc, client) => ({ ...acc, [client.code]: client }),
        {} as { [clientCode: string]: ClientData },
      ),
    ),
  );

  departments$: Observable<ListItem[]>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientCode && changes.clientCode.currentValue !== changes.clientCode.previousValue) {
      this.departments$ = this.store.select(
        selectClientDepartments(this.clientCode)).pipe(
        map((departments) => departments.filter(
          (department) =>
            !this.departmentUserService.isDepartmentUserDisabled(this.authenticationService.currentUser.id, department),
        )),
        map((departments) => departments.map((department) => ({
          value: department.id,
          display: department.name,
        }))),
      );

      if (!changes.clientCode.firstChange) {
        this.departments$.pipe(first()).subscribe((departments) => {
          if (departments.length === 1) {
            this.departmentIdsChange.emit([departments[0].value]);
          } else {
            this.departmentIdsChange.emit([]);
          }
        });
      }
    }
  }

}
