<div class="relative cursor-pointer group">
  <ng-content></ng-content>
  @if (isActive) {
    <button
      class="!absolute top-0 right-0 z-30 md:opacity-0 md:group-hover:opacity-100 transition-opacity" 
      tuiButtonClose
      tuiIconButton
      type="button"
      data-testid="badge"
    >
      Supprimer
    </button>
  }
</div>