<tui-elastic-container>
  @if ((isLoading$ | async) === false) {
    <pxc-share (cancel)="close()" (confirm)="confirm($event)">
      <pxc-sequence-preview [sequence]="sequence"></pxc-sequence-preview>
    </pxc-share>
  } @else {
    <pxc-loader size="tiny">
      Partage de votre séquence en cours ...
    </pxc-loader>
  }
</tui-elastic-container>
