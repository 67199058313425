import { TuiFieldErrorPipe } from '@taiga-ui/kit';
import { TuiError } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepartmentPickerComponent } from '@modules/department/department-picker/department-picker.component';
import { TelemonitoringRequestForm } from '../telemonitoring-request/telemonitoring-request-form';

@Component({
  selector: 'pxc-telemonitoring-request-location',
  standalone: true,
  imports: [
    CommonModule,
    DepartmentPickerComponent,
    TuiError,
    TuiFieldErrorPipe,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './telemonitoring-request-location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringRequestLocationComponent {

  @Input() form: FormGroup<TelemonitoringRequestForm>;

  get clientCode(): string {
    return this.form.get('clientCode').value;
  }

  get departmentIds(): number[] {
    return this.form.get('departmentIds').value;
  }

  setDepartmentIds(departmentIds: number[]): void {
    this.form.get('departmentIds').setValue(departmentIds);
    this.form.get('departmentIds').markAsTouched();
  }

}
