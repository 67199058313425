import { TuiCheckbox } from '@taiga-ui/kit';
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserFlattenedLabels } from 'src/app/shared/store/labels/labels.selectors';
import { labelsActions } from 'src/app/shared/store/labels/labels.actions';
import { DialogService } from 'src/app/services/dialog.service';
import { LabelCreateComponent } from '../../label/label-create/label-create.component';
import { TuiDialogContext, TuiDialogService, TuiLabel, TuiButton, TuiTitle } from '@taiga-ui/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { CommonModule } from '@angular/common';
import { LabelTableComponent } from '../../label/label-table/label-table.component';
import { LabelComponent } from '../../label/label/label.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { FlattenedLabel } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-labels',
  templateUrl: './labels.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LabelTableComponent,
    TuiTitle,
    TuiButton,
    TuiLabel,
    LabelComponent,
    FormsModule,
    ReactiveFormsModule,
    TuiCheckbox,
  ],
})
export class LabelsComponent implements OnInit {

  @ViewChild('removeDialog') removeDialog: TemplateRef<HTMLElement>;

  labels$: Observable<FlattenedLabel[]>;

  currentLabel: FlattenedLabel;
  removeFromSequences = false;

  actions: MenuAction[] = [
    {
      hint: 'Supprimer',
      icon: '@tui.trash',
      execute: (label: FlattenedLabel): void => {
        this.openRemoveDialog(this.removeDialog, label);
      },
      status: Status.ERROR,
    },
  ];

  constructor(
    private readonly store: Store,
    private readonly dialogService: DialogService,
    private readonly tuiDialogService: TuiDialogService,
  ) { }

  ngOnInit(): void {
    this.labels$ = this.store.select(selectUserFlattenedLabels);
  }

  openCreateLabelDialog(): void {
    this.dialogService.openComponentWithCloseConfirmation<LabelCreateComponent, string[]>(
      LabelCreateComponent,
      { label: 'Ajouter des mots clés' },
    ).subscribe((labels: string[]) => {
      if (labels && labels.length > 0) {
        this.store.dispatch(
          labelsActions.createLabels({
            labels,
          }),
        );
      }
    });
  }

  openRemoveDialog(dialog: PolymorpheusContent<TuiDialogContext>, label: FlattenedLabel): void {
    this.currentLabel = label;
    this.tuiDialogService.open(dialog, {
      label: 'Êtes vous sûr de ne plus vouloir utiliser ce mot clé ?',
    }).subscribe();
  }

  removeLabel(dialogRef: Subscription): void {
    dialogRef.unsubscribe();
    this.store.dispatch(
      labelsActions.unsubscribeFromLabel({
        labelId: this.currentLabel.labelInstance.id,
        removeFromSequences: this.removeFromSequences,
      }),
    );
  }

}
