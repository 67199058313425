<div [formGroup]="form" class="flex flex-col gap-6">

  <label tuiLabel>
    Nom du télésuivi
    <tui-input
      formControlName="name"
      [tuiTextfieldLabelOutside]="true"
      data-testid="request-name"
      ngDefaultControl
    >
      {{ defaultName }}
    </tui-input>
    <tui-error
      formControlName="name"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </label>

  <label tuiLabel>
    Ajouter un message
    <tui-textarea
      formControlName="message"
      [tuiTextfieldLabelOutside]="true"
      data-testid="request-message"
      ngDefaultControl
    >
      Saisir un message personnalisé (facultatif)
    </tui-textarea>
    <tui-error
      formControlName="message"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </label>

  @if (creatorType === CreatorType.BY_PATIENT) {
    <div class="border-t border-border-hover pt-6 flex flex-col gap-6">
      <label tuiLabel tuiLabelRequired>
        Numéro de téléphone du patient
        <pxc-patient-contact-input
          formControlName="patientContact"
          [patientId]="patientId"
          [clientCode]="clientCode"
          data-testid="patient-contact"
          ngDefaultControl
        ></pxc-patient-contact-input>
        <tui-error
          formControlName="patientContact"
          [error]="[] | tuiFieldError | async"
        ></tui-error>
        <p class="text-sm text-secondary mt-2">
          Le patient recevra un lien pour déposer ses photos par SMS.<br/>Le mot de passe affiché sur le tutoriel de télésuivi est nécessaire pour y accéder.
        </p>
      </label>


      <label tuiLabel tuiLabelRequired>
        Formulaire à compléter par le patient
        <pxc-form-select
          formControlName="patientForm"
          ngDefaultControl
          data-testid="patient-form"
        ></pxc-form-select>
        <tui-error
          formControlName="patientForm"
          [error]="[] | tuiFieldError | async"
        ></tui-error>
      </label>
    </div>
  }
</div>