@if (isDataLoaded$ | async) {
  @if (telemonitorings$ | async; as telemonitorings) {
    @if (telemonitorings.data) {
      <pxc-telemonitoring-list
        [telemonitorings]="telemonitorings.data"
        data-testid="telemonitoring-list"
        [displayPatient]="false"
        [actions]="telemonitoringActions"
        (loadNext)="loadNext()"
        (loadPrevious)="loadPrevious()"
        [isNextPageLoading]="telemonitorings.context.isNextPageLoading"
        [isPreviousPageLoading]="telemonitorings.context.isPreviousPageLoading"
      >
        <button
          tuiButton
          *tuiLet="patient$ | async as patient"
          emptyState
          iconStart="@tui.plus"
          [disabled]="patient.isArchived"
          (click)="createTelemonitoring()"
        >
          Démarrer un télésuivi
        </button>
      </pxc-telemonitoring-list>
    } @else {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
  }
} @else {
  <ng-container [ngTemplateOutlet]="loading"></ng-container>
}

<ng-template #loading>
  <div class="my-4">
    <pxc-loader data-testid="loading">
      Chargement des télésuivis ...
    </pxc-loader>
  </div>
</ng-template>
