import {
  ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Optional, Output,
} from '@angular/core';
import { FormReport } from '@pixacare/pxc-ts-core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'pxc-sadm-entity-form-summary',
  templateUrl: './sadm-entity-form-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SadmEntityFormSummaryComponent {

  @Input() sadmEntityId: number = this.context?.data.sadmEntityId;
  @Input() clientCode: string = this.context?.data.clientCode;
  @Input() reports: FormReport[] = this.context?.data.reports;
  @Input() fullScreen = this.context?.data.fullScreen;
  @Input() currentIndex = this.context?.data.currentIndex || 0;

  @Output() readonly currentIndexChange = new EventEmitter<number>();

  mathMin = Math.min;

  constructor(
    private readonly dialogService: DialogService,
    @Optional() @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, {
      sadmEntityId: number;
      reports: FormReport[];
      currentIndex: number;
      clientCode: string;
      fullScreen?: boolean;
    }>,
  ) {}

  setCurrentIndex(index: number): void {
    this.currentIndex = index;
    this.currentIndexChange.emit(index);
  }

  openFullscreen(): void {
    this.dialogService.openComponent(SadmEntityFormSummaryComponent, {
      label: this.reports[0].title,
      size: 'l',
      data: {
        sadmEntityId: this.sadmEntityId,
        reports: this.reports,
        currentIndex: this.currentIndex,
        clientCode: this.clientCode,
        fullScreen: true,
      },
    }).subscribe();
  }

}

