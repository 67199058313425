@if (sadms$ | async; as sadms) {
  <div class="flex flex-col gap-2">
    @if (sadms.entities.length > 0) {
      <div class="grid sm:grid-cols-2 gap-2">
        @for (entity of sadms.entities; track entity) {
          <pxc-sadm-entity-preview
            [sadmEntity]="entity"
            [sadmType]='sadms.client.sadmType'
            [isSelected]="entity.id === mergeToEntityId"
            (click)="mergeToEntityId = entity.id"
            [attr.data-testid]="entity.id"
          ></pxc-sadm-entity-preview>
        }
      </div>
    } @else {
      <p class="text-secondary my-5 text-center" data-testid="no-other-sadm">
        Vous n'avez aucune autre {{SadmTypeLabel[sadms.client.sadmType]}} pour ce patient.
      </p>
    }
    <div class="flex justify-between gap-2 mt-2 max-md:flex-col-reverse">
      <button tuiButton appearance="outline" type="button" (click)="cancel()" data-testid="cancel">Annuler</button>
      <button [disabled]="!mergeToEntityId" tuiButton type="button" (click)="merge()" data-testid="confirm">Confirmer</button>
    </div>
  </div>
}