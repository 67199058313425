import { TuiButton } from '@taiga-ui/core';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FilterBarService } from '../filter-bar.service';
import { FilterType } from 'src/app/shared/models/filters/filter-type.enum';
import { Observable, combineLatest, first, map, of, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutoCompleteModel } from 'src/app/shared/models/helpers/auto-complete-model';
import { LocationService } from 'src/app/services/location.service';
import { ActivatedRoute } from '@angular/router';
import { FlattenedFilterTemplate } from 'src/app/shared/models/filters/flattened-filter-template';
import { makeFilter } from '@shared/utils/filter-utils';

@UntilDestroy()
@Component({
  selector: 'pxc-filter-buttons',
  templateUrl: './filter-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
  ],
})
export class FilterButtonsComponent implements OnInit {

  public selectedIndex = 0;
  public template$: Observable<FlattenedFilterTemplate>;
  public buttons$: Observable<AutoCompleteModel[]>;

  private readonly filterBarService = inject(FilterBarService);
  private readonly locationService = inject(LocationService);
  private readonly route = inject(ActivatedRoute);

  ngOnInit(): void {
    this.template$ = this.filterBarService.templates$.pipe(
      untilDestroyed(this),
      map((templates) => templates
        .find((template) => template.type === FilterType.BUTTONS),
      ),
    );
    this.buttons$ = this.template$.pipe(
      untilDestroyed(this),
      switchMap((template) => template ? template.getValue('') : of(null)),
    );
    this.getSelectedIndex().subscribe({ next: (index) => this.updateFilters(index) });
  }

  onButtonClick(button: AutoCompleteModel, index: number): void {
    this.setSelectedIndex(index);
    this.template$.pipe(first()).subscribe((template) => {
      this.locationService.updateUrlParams(this.route, { [template.property]: button.value });
    });
  }

  updateFilters(index: number): void {
    this.setSelectedIndex(index);
    combineLatest([
      this.template$.pipe(first()),
      this.buttons$.pipe(first(), map((buttons) => buttons[index])),
    ]).pipe(untilDestroyed(this)).subscribe(([template, button]) => {
      this.filterBarService.updateTemplate({
        ...template,
        filters: [
          ...(button.value ? [makeFilter(button, template)] : []),
        ],
      });
    });
  }

  getSelectedIndex(): Observable<number> {
    return combineLatest([
      this.template$.pipe(first()),
      this.buttons$.pipe(first()),
      this.route.queryParams,
    ]).pipe(
      untilDestroyed(this),
      map(([template, buttons, queryParams]) =>
        buttons.findIndex((button) => button.value === queryParams[template.property]),
      ),
    );
  }

  setSelectedIndex(index: number): void {
    this.selectedIndex = index;
  }

}
