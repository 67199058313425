import { CommonModule } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { DialogService } from '@services/dialog.service';
import { TelemonitoringHttpService } from '@services/http/telemonitoring.http.service';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { TuiLink } from '@taiga-ui/core';

@Component({
  selector: 'pxc-telemonitoring-redirect-button',
  standalone: true,
  imports: [
    CommonModule,
    TuiLink,
  ],
  templateUrl: './telemonitoring-redirect-button.component.html',
})
export class TelemonitoringRedirectButtonComponent {

  @Input() telemonitoringId: number;

  private router = inject(Router);
  private dialogService = inject(DialogService);
  private authenticationService = inject(AuthenticationService);
  private telemonitoringService = inject(TelemonitoringHttpService);

  redirectToTelemonitoring(): void {
    this.telemonitoringService.getTelemonitoring(this.telemonitoringId).pipe(
      catchError((error) => {
        if (error.status !== HttpStatusCode.Forbidden) {
          return throwError(() => error);
        }
        return this.addTelemonitoringUser();
      }),
    ).subscribe({
      next: () => {
        this.navigateToTelemonitoring();
      },
    });
  }

  private addTelemonitoringUser(): Observable<void> {
    const dialogData =  {
      content: `Vous ne faites actuellement pas partie du télésuivi pour ce patient.<br>
        En cliquant sur "Accepter", vous rejoindrez les soignants participant à ce télésuivi 
        et serez notifié de l'ajout de nouvelles données médicales.`,
      yes: 'Accepter',
      no: 'Refuser',
      size: 'm',
    };
    return this.dialogService.openConfirm('Rejoindre le télésuivi', dialogData).pipe(
      switchMap((answer) => {
        if (!answer) {
          return throwError(() => new Error());
        }
        return this.telemonitoringService.addTelemonitoringUser(
          this.telemonitoringId,
          this.authenticationService.currentUser.id,
        );
      }),
    );
  }

  private navigateToTelemonitoring() {
    this.router.navigate(
      ['/', 'dashboard', 'telemonitorings', `${this.telemonitoringId}`],
      { queryParamsHandling: 'merge' },
    );
  }

}
