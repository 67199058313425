import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { BehaviorSubject, first, ReplaySubject, startWith } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareHttpService } from 'src/app/services/http/share.http.service';
import { AppConfigHttpService } from 'src/app/services/http/app-config.http.service';
import {
  passwordValidator,
  phoneNumberValidator,
} from 'src/app/shared/validators/validators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { InvitationTokenStatus } from 'src/app/shared/models/enums/invitation-token-status';
import { TuiAlertService } from '@taiga-ui/core';
import { ValidationCodeActionType } from 'src/app/shared/models/enums/validation-code-action-type.enum';
import { SignUpConfiguration, User } from '@pixacare/pxc-ts-core';

@Component({
  templateUrl: './register.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {

  registerForm: UntypedFormGroup;
  currentUser: User;
  actionOnGoing$ = new BehaviorSubject(false);

  signUpConfiguration: SignUpConfiguration;
  signUpInvitationToken = '';

  invitationTokenStatus = new BehaviorSubject<InvitationTokenStatus>(InvitationTokenStatus.VALID);
  emailFromInvitationToken = new ReplaySubject(1);
  InvitationTokenStatus = InvitationTokenStatus;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly configurationService: AppConfigHttpService,
    private readonly shareService: ShareHttpService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    private readonly authenticationService: AuthenticationService,
  ) { }

  get inputFirstName(): AbstractControl {
    return this.registerForm.get('firstName');
  }

  get inputLastName(): AbstractControl {
    return this.registerForm.get('lastName');
  }

  get inputMailAddress(): AbstractControl {
    return this.registerForm.get('mailAddress');
  }

  get inputPassword(): AbstractControl {
    return this.registerForm.get('password');
  }

  get inputPasswordConfirm(): AbstractControl {
    return this.registerForm.get('passwordConfirm');
  }

  get inputPhoneNumber(): AbstractControl {
    return this.registerForm.get('phoneNumber');
  }

  get inputCGVU(): AbstractControl {
    return this.registerForm.get('CGVU');
  }

  get inputMedicalSpecialtyId(): AbstractControl {
    return this.registerForm.get('medicalSpecialtyId');
  }

  ngOnInit(): void {

    this.currentUser = this.authenticationService.currentUser;

    if (this.currentUser != null) {
      this.router.navigate(['/']);
    }

    this.signUpConfiguration = this.configurationService.configuration.signUp;
    this.registerForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      lastName: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      mailAddress: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
        Validators.maxLength(256),
      ]),
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
        passwordValidator(
          this.configurationService.configuration.inputValidation.password.regex,
        ),
      ]),
      passwordConfirm: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
        passwordValidator(
          this.configurationService.configuration.inputValidation.password.regex,
        ),
      ]),
      phoneNumber: new UntypedFormControl(null, [
        phoneNumberValidator(
          this.configurationService.configuration.inputValidation.phoneNumber
            .regex,
        ),
      ]),
      medicalSpecialtyId: new UntypedFormControl(null),
      CGVU: new UntypedFormControl(false, [Validators.requiredTrue]),
    });

    this.route.queryParams.subscribe((params) => {
      this.signUpInvitationToken = params.signUpToken;
      if (this.signUpInvitationToken) {
        this.actionOnGoing$.next(true);
        this.authenticationService
          .getUserByInvitationToken(this.signUpInvitationToken)
          .subscribe({
            next: (userResponse) => {
              if (userResponse.userInvitationToken.claimedOn) {
                this.invitationTokenStatus.next(InvitationTokenStatus.CONSUMED);
              } else {
                this.emailFromInvitationToken.next(userResponse.mailAddress);
                this.inputMailAddress.setValue(userResponse.mailAddress);
              }
            },
            error: () => {
              this.invitationTokenStatus.next(InvitationTokenStatus.INVALID);
            },
            complete: () => {
              this.actionOnGoing$.next(false);
            },
          });
      }
    });
  }

  register(): void {
    if (!this.registerForm.valid) {
      this.alertService.open('Merci de résoudre les erreurs de saisie.', {
        label: 'Erreur de validation',
        appearance: 'error',
      }).subscribe();
      return;
    }

    this.actionOnGoing$.next(true);

    this.emailFromInvitationToken.pipe(
      startWith(null),
      first(),
    ).subscribe((invitationEmail) => {
      this.authenticationService.register({
        ...this.registerForm.value,
        mailAddress: invitationEmail || this.inputMailAddress.value,
        invitationToken: this.signUpInvitationToken,
      }).subscribe({
        next: () => {
          this.actionOnGoing$.next(false);
          this.route.queryParams.subscribe((params) => {
            const shareInvitationToken = params.token;
            if (shareInvitationToken) {
              this.shareService
                .claimShareInvite(shareInvitationToken)
                .subscribe(() => {});
            }

            this.router.navigate(['/user/validate'], { state: { actionType: ValidationCodeActionType.REGISTER } });
          });
        },
        error: (err: HttpErrorResponse) => {
          this.actionOnGoing$.next(false);

          let title = '';
          let message = '';
          if (err.status === HttpStatusCode.Conflict) {
            title = 'Adresse email connnue';
            message = 'Cette adresse email est déjà associée à un compte Pixacare, '
                + "merci d'en choisir une nouvelle.";
          } else {
            console.error(err);
            title = 'Erreur Système';
            message = "Une erreur s'est produite, merci de réessayer plus tard.";
          }
          this.alertService.open(message, {
            label: title,
            appearance: 'error',
          }).subscribe();
        },
      });
    });
  }

}
