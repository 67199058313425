<div class="flex flex-col gap-6 bg-base p-3">

  <div class="flex flex-col gap-2">
    <p class="text-sm text-secondary">Epaisseur: {{width}}</p>
    <input type="range"
      tuiSlider
      [min]="colorPickerConfig.minWidth"
      [max]="colorPickerConfig.maxWidth"
      [ngModel]="width"
      (ngModelChange)="selectWidth($event)"
      data-testid="slider-color-picker"
    >
  </div>

    <div class="grid grid-cols-4 gap-2">
    @for (defaultColor of defaultColors; track defaultColor) {
      <button
        type="button"
        class="rounded-full w-8 h-8 border transition"
        [style.background-color]="defaultColor.code"
        (click)="selectColor(defaultColor)"
        [attr.data-testid]="defaultColor.name"
        [tuiHint]="defaultColor.name"
        [ngClass]="
          defaultColor.name === color.name 
          ? 'ring-4 ring-accent-1/10 !shadow-lg' 
          : 'border-border-normal'
        "
      ></button>
    }
  </div>
</div>