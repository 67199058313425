@if ((!!sadmEntityId || !!sadmEntityChange) && !!analysisFormAnswers) {
  <div tuiCardLarge tuiSurface="outline" class="flex !flex-row max-md:!flex-col md:justify-between md:items-center gap-4">
    <div class="flex gap-4 items-center">
      <tui-icon  icon="@tui.check" class="text-status-positive"></tui-icon>
      <div class="flex flex-1 flex-col">
        <p class="text-status-positive font-bold">Questionnaire complété</p>
        <p class="text-sm text-secondary">
          {{ sadmEntityName$ | async | titlecase }}
        </p>
      </div>
    </div>
    <div class="flex gap-2 max-md:flex-col max-md:justify-stretch">
      <button
        tuiButton
        appearance="outline"
        iconEnd="@tui.pencil"
        size="s"
        data-testid="analysis-edit-button"
        (click)="editAnalysis()"
      >
        Éditer
      </button>
      <button
        tuiButton
        appearance="destructive"
        iconEnd="@tui.trash"
        size="s"
        (click)="deleteAnalysis()"
        data-testid="analysis-delete-button"
      >
        Supprimer
      </button>
    </div>
  </div>
} @else {
  <div class="flex flex-col gap-2" *tuiLet="sadmEntityName$ | async as sadmEntityName">
    @if (!!sadmEntityId && sadmEntityName) {
      <div class="flex gap-4 md:items-center md:justify-between max-md:flex-col">
        <p class="text-sm text-secondary">Lésion sélectionnée :
          <a
            class="font-bold text-action transition"
            (click)="changeSadmEntityId()"
            [ngClass]="{'cursor-pointer hover:text-action-hover': !preventSadmEntityIdChange}"
            data-testid="selected-sadm-entity"
          >
            {{ sadmEntityName | titlecase }}
            @if (!preventSadmEntityIdChange) {
              <tui-icon  icon="@tui.edit-3"></tui-icon>
            }
          </a>
        </p>
      </div>
    }
    <pxc-create-button
      label="Ajouter un questionnaire"
      icon="add-form"
      (click)="addAnalysis()"
      data-testid="analysis-create-button"
      appearance="secondary"
    ></pxc-create-button>
  </div>
}

