<div class="flex flex-wrap gap-3 min-h-20">
  @for (channel of channels; track channel) {
    <pxc-cross-badge
      (click)="removeChannel.emit(channel)"
      [isActive]="removable"
    >
      <pxc-channel-preview
        [channel]="channel"
        [showDetails]="false"
        [vertical]="true"
        size="small"
      ></pxc-channel-preview>
    </pxc-cross-badge>
  }
  @for (user of users; track user) {
    <pxc-cross-badge
      (click)="removeUser.emit(user)"
      [isActive]="removable"
    >
      <pxc-user-card
        [user]="user"
        [vertical]="true"
        [showSpecialty]="false"
        size="small"
      ></pxc-user-card>
    </pxc-cross-badge>
  }
  @for (mailAddress of mailAddresses; track mailAddress) {
    <pxc-cross-badge
      (click)="removeMailAddress.emit(mailAddress)"
      [isActive]="removable"
    >
      <pxc-user-card
        [mailAddress]="mailAddress"
        [vertical]="true"
        showSpecialty="false"
        size="small"
      ></pxc-user-card>
    </pxc-cross-badge>
  }
</div>