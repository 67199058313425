import { TuiButton } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'pxc-message-special-telemonitoring',
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
    RouterModule,
  ],
  templateUrl: './message-special-telemonitoring.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSpecialTelemonitoringComponent {

  @Input() telemonitoringId: number;

}
