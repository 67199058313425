import { TuiTextfieldControllerModule, TuiMultiSelectModule } from '@taiga-ui/legacy';
/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges }
  from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, ValidatorFn }
  from '@angular/forms';
import { TuiDataListWrapper, TuiFieldErrorPipe } from '@taiga-ui/kit';
import { AutoCompleteModel } from 'src/app/shared/models/helpers/auto-complete-model';
import { TuiError } from '@taiga-ui/core';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { SearchedUser } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-tag-input',
  templateUrl: './tag-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TuiDataListWrapper,
    UserCardComponent,
    TuiTextfieldControllerModule,
    TuiError,
    TuiFieldErrorPipe,
  ],
})
export class TagInputComponent implements OnChanges {

  @Input() placeholder = 'exemple@pixacare.com';
  @Input() helpText? = 'Appuyer sur la touche entrée pour valider.';
  @Input() validators: ValidatorFn[] = [];
  @Input() emptyMessage = 'Aucun résultat.';

  @Input() autocompleteModels: AutoCompleteModel[];
  @Input() autocompleteUsers: { [id: string]: SearchedUser };
  @Input() allowOnlyAutoComplete = false;
  @Input() disableAutocomplete = false;
  @Input() disabled = false;

  searchForm = new FormControl('');
  @Output() searchChange = this.searchForm.valueChanges;

  @Input() tags: AutoCompleteModel[] = [];
  @Output() tagsChange = new EventEmitter<AutoCompleteModel[]>();

  readonly stringify = (item: AutoCompleteModel): string => item.display;

  ngOnChanges({ validators, search }: SimpleChanges): void {

    if (!validators && !search) {
      return;
    }

    this.searchForm.setValidators(this.validators);

  }

  onSearchChange(value: string): void {
    this.searchForm.setValue(value);
    this.searchForm.markAsTouched();
  }

  addUnvalidatedTag(): void {

    if (this.allowOnlyAutoComplete) {
      return;
    }

    try {

      const value = this.searchForm.value;

      if (!value) {
        return;
      }

      if (this.searchForm.invalid) {
        throw new Error('Invalid tag');
      }

      if (this.tags.map((tag) => tag.display).includes(value)) {
        throw new Error('Tag already exists');
      }

      this.tagsChange.emit([...this.tags, { display: value, value }]);
      this.searchForm.reset('');

    } catch (error) {
      console.warn(error);
    }

  }

}
