import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { AlertReportQuestionsByQuestionId, FormReportQuestion } from '@pixacare/pxc-ts-core';
import { FormSection } from './form-section';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { CommonModule } from '@angular/common';
import { FormDisplayQuestionComponent } from '../form-display-question/form-display-question.component';
import { NormalizePipe } from '@shared/pipes/normalize.pipe';

@Component({
  selector: 'pxc-form-display',
  templateUrl: './form-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormDisplayQuestionComponent,
    NormalizePipe,
  ],
})
export class FormDisplayComponent {

  @Input() sections: FormSection[] = this.context?.data.sections ?? [];
  @Input() alerts: AlertReportQuestionsByQuestionId = this.context?.data.alerts ?? {};
  @Input() showAlerts = true;

  constructor(
    @Optional() @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, Partial<FormDisplayComponent>>,
  ) {}

  get isDialog(): boolean {
    return !!this.context?.data?.sections;
  }

  trackBySectionId(index: number, section: FormSection): string {
    return section.id;
  }

  trackByQuestionTitle(index: number, question: FormReportQuestion): string {
    return question.title;
  }

}
