<tui-notification tuiNotification size="m" appearance="warning">
  <div>
    <div class="font-bold">
      {{ title }}
    </div>
    <div>
      {{ description }}
    </div>
  </div>
</tui-notification>
