<div class="flex justify-center items-center aspect-square"
  [ngClass]="{
    'w-10 h-10 !rounded-lg': size === Size.SMALL,
    'w-12 h-12 rounded-lg': size === Size.MEDIUM, 
    'w-16 h-16 rounded-xl': size === Size.LARGE
  }"
  [ngStyle]="{
    'background-color': avatarColors.backgroundColor,
    'color': avatarColors.textColor
  }"
  data-testid="patient-avatar"
>
  @if (patient && patient.firstName && patient.lastName) {
    <p
      class="font-bold text-current"
      [ngClass]="{
        'text-xl': size !== Size.SMALL
      }"
    >
      {{ patient.lastName[0] | uppercase }}{{ patient.firstName[0] | uppercase }}
    </p>
  } @else {
    <p 
      class="font-bold text-current" 
      [ngClass]="{
        'text-xl': size !== Size.SMALL,
        'text-lg': size === Size.SMALL
      }">?</p>
  }
</div>
