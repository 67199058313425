import { TuiIcon } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { statusInformations } from 'src/app/shared/models/status-informations.config';

@Component({
  selector: 'pxc-status',
  templateUrl: './status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiIcon,
    CommonModule,
  ],
})
export class StatusComponent {

  @Input() state: Status;
  @Input() title: string;
  @Input() showTitle = true;
  @Input() description: string;
  @Input() icon: string;

  Status = Status;
  defaultStatusInformations = statusInformations;

}
