import { TuiButton, TuiTitle } from '@taiga-ui/core';
import { TuiBadge } from '@taiga-ui/kit';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable, combineLatest, switchMap } from 'rxjs';
import { LabelTableComponent } from '../../label/label-table/label-table.component';
import { Store } from '@ngrx/store';
import { selectDepartmentFlattenedLabels } from 'src/app/shared/store/labels/labels.selectors';
import { Department } from 'src/app/shared/models/base/department';
import { DepartmentLabelsAdminService } from './department-labels-admin.service';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { DepartmentAdminService } from '../department-admin/department-admin.service';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';
import { FlattenedLabel } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-department-labels-admin',
  standalone: true,
  imports: [
    CommonModule,
    LabelTableComponent,
    TuiBadge,
    TuiTitle,
    TuiButton,
  ],
  providers: [
    DepartmentLabelsAdminService,
  ],
  templateUrl: './department-labels-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentLabelsAdminComponent implements OnInit {

  labels$: Observable<FlattenedLabel[]>;
  department$: Observable<Department>;

  actions: MenuAction[] = [];

  private readonly departmentAdminService = inject(DepartmentAdminService);
  private readonly departmentLabelsAdminService = inject(DepartmentLabelsAdminService);
  private readonly store = inject(Store);

  ngOnInit(): void {

    this.labels$ = combineLatest([
      this.departmentAdminService.loadDepartment$(),
      this.store.select(selectClientCode),
    ]).pipe(
      switchMap(([department, clientCode]) => this.store.select(
        selectDepartmentFlattenedLabels(department.id, clientCode),
      )),
    );
    this.department$ = this.departmentAdminService.loadDepartment$();
  }

  addLabels(): void {
    this.departmentLabelsAdminService.createLabels();
  }

}
