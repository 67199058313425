<div class="overflow-y-auto h-full py-4">
  <div class="flex flex-col gap-8 w-full overflow-x-hidden py-16">

    <button
      tuiIconButton
      appearance="flat"
      iconStart="@tui.x"
      (click)="closeClick.emit()"
      data-testid="close-button"
      class="ml-4"
    ></button>

    <div class="flex flex-col gap-2 items-center justify-center px-6">
      <pxc-channel-avatar
        [channelType]="channel?.type"
        [channelUsers]="channel?.users"
        size="large"
        data-testid="channel-avatar"
      ></pxc-channel-avatar>
      <h5 tuiTitle="m" class="text-center">
        {{ channel | channelName }}
        <span tuiSubtitle class="text-center" data-testid="channel-type">
          {{ channel | channelType }}
        </span>
      </h5>
      @if (channel?.topic.clientCode) {
        <div class="bg-neutral-1 px-2 py-1 hidden">
          <p class="text-secondary text-center text-xs" data-testid="channel-topic">
            {{ channel?.topic.clientCode }}
            <!-- todo : display client fullName -->
          </p>
        </div>
      }
      <a tuiButton appearance="outline" [chatEntityLink]="channel">
        Ouvrir
      </a>

    </div>

    <div class="flex flex-col gap-2 px-6">
      <p class="text-secondary">Participants</p>

      <pxc-channel-participants
        [participants]="channel?.users"
        data-testid="channel-participants"
      ></pxc-channel-participants>

    </div>

    <div class="flex flex-col gap-2">
      <p class="text-secondary px-6">Réglages</p>
      <pxc-channel-notifications [channel]="channel"></pxc-channel-notifications>
    </div>

  </div>
</div>