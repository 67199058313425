<ng-container *tuiLet="{
  sadm: sequence.sequenceInstance.sadmEntityId,
  tm: sequence.sequenceInstance.telemonitoringId
} as ids">
  @if ((showSadm && ids.sadm) || (showTelemonitoring && ids.tm)) {
    <div
      class="border-t border-border-normal flex flex-col gap-2 mt-2.5 pt-2.5"
      data-testid="linked-entities-list"
    >
      @if (showTelemonitoring && ids.tm) {
        @if (sequencePickerService.telemonitoringNames$ | async; as names) {
          @if (names[ids.tm]; as name) {
            <pxc-sequence-picker-linked-entity
              icon="telemonitoring"
              [name]="name"
              [link]="'Sélectionner toutes les photos liées au télésuivi'"
              (linkClicked)="selectTelemonitoringSequences.emit(ids.tm)"
              data-testid="telemonitoring-linked-entity"
            ></pxc-sequence-picker-linked-entity>
          }
        } @else {
          <ng-container [ngTemplateOutlet]="loading"></ng-container>
        }
      }
      @if (showSadm && ids.sadm) {
        @if (sequencePickerService.sadmNames$ | async; as names) {
          @if (names[ids.sadm]; as name) {
            <pxc-sequence-picker-linked-entity
              icon="@tui.chart-column-increasing"
              [name]="name"
              [link]="'Sélectionner toutes les photos liées à la plaie'"
              (linkClicked)="selectSadmSequences.emit(ids.sadm)"
              data-testid="sadm-linked-entity"
            ></pxc-sequence-picker-linked-entity>
          }
        } @else {
          <ng-container [ngTemplateOutlet]="loading"></ng-container>
        }
      }
    </div>
  }
</ng-container>

<ng-template #loading>
  <div class="flex gap-3" data-testid="loading">
    <div class="w-6 h-6 tui-skeleton"></div>
    <div>
      <div class="w-24 h-4 tui-skeleton"></div>
      <div class="w-16 h-4 mt-2 tui-skeleton"></div>
    </div>
  </div>
</ng-template>