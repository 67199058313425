import { formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PatientHttpService } from './patient.http.service';
import {
  BaseSadmEntity,
  Filter,
  FormAnswer,
  FormAnswers,
  PagedResponse,
  PaginationQuery,
  Patient,
  Sequence,
} from '@pixacare/pxc-ts-core';
import { filterEntities, dateRangeFilterHandler } from 'src/app/shared/utils/filter-utils';
import { formatToFullDate, match } from 'src/app/shared/utils/utils';
import { TuiAlertService } from '@taiga-ui/core';
import { appendToFormData } from 'src/app/shared/utils/form-data-utils';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

@Injectable({
  providedIn: 'root',
})
export class SequenceHttpService {

  constructor(
    private readonly patientService: PatientHttpService,
    private readonly http: HttpClient,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    private readonly router: Router,
  ) {}

  static appendAnalysisToFormData(data: FormData, { sadmEntity, sadmEntityId }: {
    sadmEntity?: BaseSadmEntity;
    sadmEntityId?: number;
  }): FormData {
    if (sadmEntity) {
      appendToFormData(data, {
        sadmEntityCreationFormAnswer: JSON.stringify(sadmEntity.entityCreationFormAnswers),
        sadmClientId: sadmEntity.sadmClientId,
        sadmEntityName: sadmEntity.name,
      });
    }
    if (sadmEntityId) {
      appendToFormData(data, { sadmEntityId });
    }
    return data;
  }

  getSequences(
    clientCode: string,
    {
      query = new PaginationQuery({ orderBy: ['created_on|desc'] }),
      fillThumbnailUri = true,
      fillFullSizeUri = false,
      forms = false,
    } = {},
  ): Observable<PagedResponse<FlattenedSequence>> {

    return this.http.get<PagedResponse<FlattenedSequence>>(
      `${environment.apiBaseUrl}/Sequence/${clientCode}`,
      {
        headers: { 'X-version': '1.1' },
        params: {
          fillFullSizeUri: String(fillFullSizeUri),
          fillThumbnailUri: String(fillThumbnailUri),
          forms: String(forms),
          paginationOptions: String(query.encodedQuery || query.encode() || ''),
        },
      },
    );
  }

  getSequence(
    clientCode: string,
    sequenceId: number,
    {
      fillThumbnailUri = true,
      fillFullSizeUri = false,
      forms = false,
    } = {},
  ): Observable<FlattenedSequence> {
    return this.http.get<FlattenedSequence>(`${environment.apiBaseUrl}/Sequence/${clientCode}/${sequenceId}`, {
      params: {
        fillFullSizeUri: String(fillFullSizeUri),
        fillThumbnailUri: String(fillThumbnailUri),
        forms: String(forms),
      },
    },
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) {
          this.alertService.open('Cette séquence n\'est plus disponible car elle a été supprimée.', {
            label: 'Séquence supprimée',
            appearance: 'error',
          }).subscribe();
        }
        return [];
      }),
    );
  }

  createSequence(data: FormData): Observable<FlattenedSequence> {
    const loadingToastr = this.alertService.open('Veuillez patienter quelques instants ...', {
      label: 'Synchronisation de la séquence ...',
      appearance: 'info',
      closeable: false,
      autoClose: 10000,
    }).subscribe();

    return this.http
      .post<FlattenedSequence>(
      `${environment.apiBaseUrl}/Sequence/CreateSequence`,
      data,
      { headers: { 'X-version': '1.1' } },
    ).pipe(tap(() => {
      loadingToastr.unsubscribe();
    }));
  }

  getUserTelemonitoringSequences(tmId: number): Observable<FlattenedSequence[]> {
    return this.http.get<FlattenedSequence[]>(
      `${environment.apiBaseUrl}/telemonitoring/${tmId}/sequences`,
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        this.router.navigate(['/']);
        return throwError(() => error);
      }),
    );
  }

  patchSequence(clientCode: string, sequence: Sequence): Observable<Sequence> {
    return this.http.patch<Sequence>(
      `${environment.apiBaseUrl}/sequence/${sequence.id}`,
      {
        createdOn: formatToFullDate(sequence.createdOn),
        description: sequence.description,
      },
      {
        params: {
          clientCode,
        },
      },
    );
  }

  deleteSequenceMedia(clientCode: string, sequenceId: number, mediaId: number): Observable<FlattenedSequence> {
    return this.http.delete<FlattenedSequence>(
      `${environment.apiBaseUrl}/sequence/${sequenceId}/media/${mediaId}`, {
        params: {
          clientCode,
        },
      },
    );
  }

  deleteSequence(clientCode: string, sequenceId: number): Observable<unknown> {
    return this.http
      .delete(
        `${environment.apiBaseUrl}/Sequence/${clientCode}/${sequenceId}`,
      );
  }

  attachSequenceMedias(clientCode: string, sequenceId: number, medias: File[]): Observable<FlattenedSequence> {
    const data = new FormData();
    medias.forEach((media) => {
      data.append('images', media);
    });
    return this.http.post<FlattenedSequence>(
      `${environment.apiBaseUrl}/sequence/${sequenceId}/media`,
      data,
      {
        params: {
          clientCode,
        },
      },
    );
  }

  search(sequence: FlattenedSequence, value: string): boolean {
    const tags = [
      ...sequence.labels.map((label) => label?.word),
      formatDate(sequence.sequenceInstance.createdOn, 'short', 'fr'),
      sequence.createdByInstance?.firstName,
      sequence.createdByInstance?.lastName,
    ];
    if (sequence.patientInstance) {
      return (
        match(value, tags)
        || this.patientService.filter(sequence.patientInstance, value)
      );
    }
    return match(value, tags);
  }

  filterSequences(sequences: FlattenedSequence[], search: string, filters: Filter[]): FlattenedSequence[] {
    return filterEntities(sequences, {
      search: {
        value: search,
        handler: this.search.bind(this),
      },
      filters: {
        values: filters,
        handlers: {
          created_on: (entity: FlattenedSequence, filter: Filter) =>
            dateRangeFilterHandler(entity, filter, 'sequenceInstance.createdOn'),
        },
      } });
  }

  moveSequence(
    fromClientCode: string,
    toClientCode: string,
    sequenceId: number,
    overridePatient: boolean,
  ): Observable<FlattenedSequence> {
    return this.http.post<FlattenedSequence>(
      `${environment.apiBaseUrl}/Sequence/Move/`
      + `${sequenceId}`,
      {
        fromClientCode,
        toClientCode,
        sequenceId,
        overridePatient,
      },
    );
  }

  updateSequenceLabels(
    clientCode: string,
    userId: number,
    sequenceId: number,
    existingLabels: number[],
    createdLabels: string[],
  ): Observable<FlattenedSequence> {
    return this.http
      .patch<FlattenedSequence>(
      `${environment.apiBaseUrl}/Sequence/UpdateLabels`,
      {
        clientCode,
        userId,
        sequenceId,
        existingLabels,
        createdLabels,
      },
    );
  }

  updateSequencePatient(
    clientCode: string,
    sequenceId: number,
    patient: Patient,
  ): Observable<FlattenedSequence> {
    return this.http.put<FlattenedSequence>(
      `${environment.apiBaseUrl}/Sequence/${sequenceId}/patient`,
      {
        id: patient.id,
        businessIdentifier: patient.businessIdentifier,
        firstName: patient.firstName,
        lastName: patient.lastName,
        birthName: patient.birthName,
        birthDate: formatToFullDate(patient.birthDate),
        isGamLinked: patient.isGamLinked,
      },
      { params: { clientCode } },
    );
  }

  addSequenceAnalysis({ clientCode, sequenceId, sadmEntityId,
    sadmEntity, answers, protocolCreationFormAnswer }: {
    clientCode: string;
    sequenceId: number;
    sadmEntityId?: number;
    sadmEntity?: BaseSadmEntity;
    answers: FormAnswers;
    protocolCreationFormAnswer?: FormAnswer;
  }): Observable<Sequence> {
    const data = new FormData();
    appendToFormData(data, {
      answers: JSON.stringify(answers),
      ...(protocolCreationFormAnswer && {
        protocolCreationFormAnswer: JSON.stringify(protocolCreationFormAnswer),
      }),
    });
    return this.http.post<Sequence>(
      `${environment.apiBaseUrl}/Sequence/${sequenceId}/analysis`,
      SequenceHttpService.appendAnalysisToFormData(data, {
        sadmEntity, sadmEntityId,
      }),
      {
        params: { clientCode },
      },
    );
  }

  editSequenceAnalysis({ clientCode, sequenceId, answers }: {
    clientCode: string;
    sequenceId: number;
    answers: FormAnswers;
  }): Observable<Sequence> {
    const data = new FormData();
    data.append('answers', JSON.stringify(answers));
    return this.http.patch<Sequence>(
      `${environment.apiBaseUrl}/Sequence/${sequenceId}/analysis`,
      data,
      {
        params: { clientCode },
      },
    );
  }

  deleteSequenceAnalysis({ clientCode, sequenceId }: {
    clientCode: string;
    sequenceId: number;
  }): Observable<Sequence> {
    return this.http.delete<Sequence>(
      `${environment.apiBaseUrl}/Sequence/${sequenceId}/analysis`,
      {
        params: { clientCode },
      },
    );
  }

  getPatientFormAnswer({ clientCode, sequenceId }: {
    clientCode: string;
    sequenceId: number;
  }): Observable<FormAnswer> {
    return this.http.get<FormAnswer>(
      `${environment.apiBaseUrl}/sequence/${clientCode}/${sequenceId}/patientFormAnswer`,
    );
  }

}
