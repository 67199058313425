<ng-container *tuiLet="((displayChannelPane$ | async) === false) as displayChannelPane">
  @if ((isMobile$ | async) === false) {
    <div class="flex items-stretch border-t border-border-normal animate-in fade-in h-full">
      <pxc-channel-list
        class="h-full w-96 z-20"
        data-testid="channel-list"
      ></pxc-channel-list>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  } @else {
    @if (displayChannelPane) {
      <pxc-channel-list
        class="w-full"
        data-testid="channel-list"
      ></pxc-channel-list>
    } @else {
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    }
  }
</ng-container>

<ng-template #content>
  <div class="flex-1 h-full" data-testid="channel-pane">
    <router-outlet></router-outlet>
  </div>
</ng-template>