import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Inject, Input, OnInit, Optional,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseSadmEntity, FlattenedSequence, FormAnswers, Media, Patient } from '@pixacare/pxc-ts-core';
import { SequenceEditService } from './sequence-edit.service';
import { FormElement } from '../../../shared/models/enums/sequence-edit.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserSequenceGalleryService } from '../services/user-sequence-gallery.service';
import { selectSequence } from 'src/app/shared/store/sequences/sequences.selectors';
import { StorageSpace } from 'src/app/shared/models/paywall/storage-space.enum';
import { StorageService } from 'src/app/services/storage.service';
import { AutoCompleteLabelModel } from 'src/app/shared/models/helpers/auto-complete-label-model';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { selectSadmClients, selectSadmEntity } from 'src/app/shared/store/sadm/sadm.selectors';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { SadmEntityCreationService } from '../../sadm/sadm-entity-creation.service';
import { SadmProtocolsService } from '../../sadm/sadm-protocols.service';
import { sadmActions } from 'src/app/shared/store/sadm/sadm.actions';

@UntilDestroy()
@Component({
  selector: 'pxc-sequence-edit',
  templateUrl: './sequence-edit.component.html',
  providers: [
    UserSequenceGalleryService,
    SequenceEditService,
    SadmEntityCreationService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SequenceEditComponent implements OnInit {

  @Input() sequenceId: number = this.context?.data.sequenceId;
  @Input() clientCode: string = this.context?.data.clientCode;

  sequence$: Observable<FlattenedSequence>;
  storageSpace: StorageSpace = StorageSpace.AVAILABLE;

  StorageSpace = StorageSpace;
  FormElement = FormElement;

  // Patient
  updatedPatient: Patient;
  basePatient: Patient;

  // Labels
  updatedLabels: AutoCompleteLabelModel[];

  // Date
  updatedDate: Date;

  // Medias
  newMedias: File[] = [];
  medias: Media[];
  deletedMediasIds: number[] = [];

  // Description
  updatedDescription: string;

  // Form state
  isPatientEditFormLoading$ = new BehaviorSubject<boolean>(false);
  isPatientEditFormValid$ = new BehaviorSubject<boolean>(true);

  // Analysis
  displayAnalysis$: Observable<boolean>;
  sadmEntityId: number;
  sadmEntity: BaseSadmEntity;
  analysisFormAnswers$: BehaviorSubject<FormAnswers>;
  protocolFormAnswers$ = new BehaviorSubject<FormAnswers>(null);
  isSadmFormLoading$ = of(false);

  constructor(
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sequenceEditService: SequenceEditService,
    private readonly userSequenceGalleryService: UserSequenceGalleryService,
    private readonly storageService: StorageService,
    @Optional() @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, {
      sequenceId: number;
      clientCode: string;
    }>,
  ) { }

  get pictureCount(): number {
    return this.medias.length + this.newMedias.length;
  }

  ngOnInit() {
    this.storageService.getActiveClientStorageSpace().subscribe((storageSpace) => this.storageSpace = storageSpace);

    this.sequence$ = this.store.select(selectSequence(this.sequenceId));

    // Initialize labels and date with info from the sequence
    this.sequence$.pipe(first()).subscribe((sequence) => {
      this.updatedLabels = sequence.labels.map<AutoCompleteLabelModel>((label) => ({
        value: label,
        display: label.word,
      }));

      this.updatedDate = new Date(sequence.sequenceInstance.createdOn);
      this.updatedPatient = sequence.patientInstance;
      this.basePatient = sequence.patientInstance;
      this.updatedDescription = sequence.sequenceInstance.description;

      this.sadmEntityId = sequence.sequenceInstance.sadmEntityId;

      this.analysisFormAnswers$ = new BehaviorSubject<FormAnswers>(sequence.sequenceInstance.formAnswer?.answers);

      if (sequence.sequenceInstance.sadmEntityId) {

        const sadmEntity$ = this.store.select(
          selectSadmEntity(sequence.sequenceInstance.sadmEntityId),
        );

        sadmEntity$.pipe(first())
          .subscribe((sadmEntity) => {
            if (!sadmEntity) {
              // load sadm entity if not done yet
              this.store.dispatch(sadmActions.getSadmEntity({
                sadmEntityId: sequence.sequenceInstance.sadmEntityId,
              }));
            }
          });

        sadmEntity$.pipe(first(Boolean))
          .subscribe((sadmEntity) => {
            this.protocolFormAnswers$.next(
              SadmProtocolsService.getLatestProtocol(sadmEntity)?.protocolCreationFormAnswer?.answers,
            );
          });
      }

      if (sequence.sequenceInstance.formAnswerId && !sequence.sequenceInstance.formAnswer) {
        this.store.dispatch(sequencesActions.getSequenceAnalysis({
          clientCode: sequence.clientCode,
          sequenceId: sequence.sequenceInstance.id,
        }));
        this.isSadmFormLoading$ = this.sequence$.pipe(
          map((s) => s.sequenceInstance.formAnswerId && !s.sequenceInstance.formAnswer));
      }

    });

    // Update sequence media & analysis in case it changes
    this.sequence$.pipe(untilDestroyed(this)).subscribe((sequence) => {
      this.medias = sequence.medias;
      this.analysisFormAnswers$.next(sequence.sequenceInstance.formAnswer?.answers);
      this.changeDetectorRef.detectChanges();
    });

    // Analysis
    this.displayAnalysis$ = combineLatest([
      this.store.select(selectSadmClients),
      this.analysisFormAnswers$,
    ]).pipe(
      map(([clients, analysisFormAnswers]) => !!analysisFormAnswers || clients.length > 0),
    );

  }

  patientChange(patient: Patient): void {
    this.updatedPatient = patient;
  }

  areLabelsValid(): boolean {
    return this.updatedLabels.length > 0;
  }

  arePicturesValid(): boolean {
    return this.pictureCount > 0;
  }

  save(): void {

    if (!this.areLabelsValid() || !this.arePicturesValid()) {
      return;
    }

    combineLatest([
      this.sequence$,
      this.analysisFormAnswers$,
      this.protocolFormAnswers$,
    ]).pipe(first())
      .subscribe(([sequence, analysisFormAnswers, protocolCreationFormAnswers]) => {

        if (this.isSequenceTouched(sequence, analysisFormAnswers)) {
          this.sequenceEditService
            .save({
              updatedPatient: this.updatedPatient,
              updatedLabels: this.updatedLabels,
              updatedDate: this.updatedDate,
              updatedDescription: this.updatedDescription,
              removedMediasId: this.deletedMediasIds,
              newMedias: this.newMedias,
              clientCode: this.clientCode,
              sadmEntityId: this.sadmEntityId,
              sadmEntity: this.sadmEntity,
              sequence,
              analysisFormAnswers,
              protocolCreationFormAnswers,
            });
        }

        this.close();
      });
  }

  close(): void {
    if (this.context) {
      this.context.completeWith();
    }
  }

  openGallery(mediaIdx: number): void {
    this.sequence$.pipe(first()).subscribe((sequence) => {
      this.userSequenceGalleryService.openGallery(
        sequence.clientCode,
        sequence.sequenceInstance.id,
        mediaIdx,
      );
    });
  }

  deleteMedia(mediaId: number): void {
    this.medias = this.medias
      .filter((media) => media.id !== mediaId);
    this.deletedMediasIds.push(mediaId);

  }

  addSequenceMedia(event: { addedFiles: File[] }): void {
    this.newMedias.push(...event.addedFiles);

    // Run change detection for this specific action
    setInterval(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  removeSequenceMedia(index: number): void {
    this.newMedias.splice(index, 1);
  }

  isSequenceTouched(sequence: FlattenedSequence, analysisFormAnswers: FormAnswers): boolean {
    return this.sequenceEditService.isSequenceTouched(
      sequence,
      {
        updatedPatient: this.updatedPatient,
        updatedLabels: this.updatedLabels,
        updatedDate: this.updatedDate,
        updatedDescription: this.updatedDescription,
        removedMediasId: this.deletedMediasIds,
        newMedias: this.newMedias,
        clientCode: this.clientCode,
        analysisFormAnswers,
        sadmEntityId: this.sadmEntityId,
        sadmEntity: this.sadmEntity,
      },
    );
  }

}
