import { TuiLet } from '@taiga-ui/cdk';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject }
  from '@angular/core';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import {
  TuiDialogContext,
  TuiDialogService,
  TuiScrollbar,
  TuiIcon,
  TuiLink,
  TuiButton,
  TuiSurface,
} from '@taiga-ui/core';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { Subscription } from 'rxjs';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { IsMobileService } from 'src/app/services/is-mobile.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PatientModule } from '../../patient/patient.module';
import {
  SequencePreviewMediaCarouselComponent,
} from '../sequence-preview-media-carousel/sequence-preview-media-carousel.component';
import { LabelComponent } from '../../label/label/label.component';
import { PatientLinkDirective } from '../../patient/patient-link.directive';
import { UserPreviewComponent } from '../../user/user-preview/user-preview.component';
import { CreatorType, FlattenedSequence } from '@pixacare/pxc-ts-core';
import { TuiCardLarge } from '@taiga-ui/layout';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    PatientModule,
    SequencePreviewMediaCarouselComponent,
    TuiLet,
    TuiCardLarge,
    TuiSurface,
    LabelComponent,
    PatientLinkDirective,
    UserPreviewComponent,
    TuiLink,
    TuiIcon,
    TuiButton,
    TuiScrollbar,
  ],
  selector: 'pxc-sequence-preview',
  templateUrl: './sequence-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencePreviewComponent {

  @Input() sequence: FlattenedSequence;
  @Input() actions: MenuAction<FlattenedSequence>[];

  // The compact mode hide the patient and remove card appearance
  @Input() showPatient = true;

  @Input() mobileMode = false;

  @Input() patientRedirect = true;

  @Output() mediaClicked: EventEmitter<MediaClickedOutput> = new EventEmitter();

  isDropdownOpen = false;
  dialogRef: Subscription;
  isMobile$ = inject(IsMobileService).maxWidth(1024);
  CreatorType = CreatorType;

  private tuiDialogService = inject(TuiDialogService);

  openSequenceDescription(dialog: PolymorpheusContent<TuiDialogContext>): void {
    this.dialogRef = this.tuiDialogService.open(dialog, {
      label: 'Description',
    }).subscribe();
  }

}
