<tui-scrollbar class="w-screen">
    <div class="flex gap-2 w-max snap-x justify-center px-2 mx-auto">
        @for (item of items; track item.mediaId; let idx = $index) {
            <pxc-gallery-thumbnail
                [item]="item"
                [active]="idx === activeIdx"
                (thumbnailClicked)="thumbnailClicked.emit(idx)"
                [attr.data-testid]="'thumbnail-'+idx"
            ></pxc-gallery-thumbnail>
        }
    </div>
</tui-scrollbar>