<tui-multi-select
  [pseudoInvalid]="searchForm.touched && searchForm.invalid"
  (blur)="addUnvalidatedTag()"
  (keydown.enter)="addUnvalidatedTag()"
  [search]="searchForm.value ?? ''"
  (searchChange)="onSearchChange($event)"
  [ngModel]="tags"
  (ngModelChange)="tagsChange.emit($event)"
  [stringify]="stringify"
  expandable="true"
  [tuiTextfieldLabelOutside]="true"
  [tuiTextfieldCleaner]="true"
  data-testid="input"
  class="hide-right-icon"
  [disabled]="disabled"
  ngDefaultControl
>
  {{ placeholder }}
  <tui-data-list-wrapper
    *tuiDataList
    [items]="(disableAutocomplete || !searchForm.value?.length) ? [] : autocompleteModels"
    [itemContent]="autocompleteUsers ? userTemplate : stringify"
    [emptyContent]="disableAutocomplete ? helpText : emptyMessage"
  ></tui-data-list-wrapper>
</tui-multi-select>

<tui-error
  data-testid="validation-error-message"
  [formControl]="searchForm"
  [error]="[] | tuiFieldError | async"
></tui-error>

@if (!disableAutocomplete && helpText) {
  <p class="text-secondary mt-2">
    {{ helpText }}
  </p>
}

<ng-template #userTemplate let-data>
  @if (autocompleteUsers?.[data.value]; as user) {
    <pxc-user-card
      [user]="user"
    ></pxc-user-card>
  }
</ng-template>