@if (dataConsumptionState$ | async; as dataConsumptionState) {
  @if (activeClientDataConsumption$ | async; as clientDataConsumption) {
    <ng-container *tuiLet="(clientDataConsumption.used * 100) / clientDataConsumption.total as percentUsed">
      @if (detailedMode) {
        <div class="flex flex-col gap-4 my-2">
          <div class="flex flex-col lg:flex-row gap-5 my-2 items-start">
            <tui-icon 
              [icon]="dataConsumptionState.icon"
              data-testid="icon"
              class="!text-2xl"
            ></tui-icon>
            <div class="flex flex-col gap-2">
              <h5 tuiTitle="m" data-testid="title">
                {{ dataConsumptionState.title(clientDataConsumption.total-clientDataConsumption.used|formatGoDataUnit) }}
              </h5>
              <div
                class="w-full flex h-6 rounded-full overflow-hidden"
                [ngClass]="dataConsumptionState.styles.containerClass"
                data-testid="progress"
              >
                <div
                  [ngStyle]="{ width: percentUsed + '%' }"
                  [ngClass]="dataConsumptionState.styles.progressClass">
                </div>
              </div>
              <p class="text-sm" [ngClass]="dataConsumptionState.styles.hintAppearance === 'error' ? 'text-negative' : 'text-secondary'">
                {{clientDataConsumption.used | formatGoDataUnit}} consommés sur {{clientDataConsumption.total | formatGoDataUnit}}
              </p>
              <p data-testid="description">
                Vous disposez de <span class="font-bold">{{ clientDataConsumption.total | formatGoDataUnit }}</span> de stockage dans votre espace sécurisé Pixacare.
              </p>
              <p>
                Contactez nous pour bénéficier davantage d'espace de stockage.
              </p>
            </div>
          </div>
          <button
            tuiButton
            (click)="contact()"
            appearance="primary"
            data-testid="contact"
          >
            Demander de l'aide
          </button>
        </div>
      } @else {
        <div
          class="rounded-full relative cursor-pointer w-full h-10 overflow-hidden transition-all hover:ring ring-border-normal/50"
          [ngClass]="dataConsumptionState.styles.containerClass"
          (click)="manageStorage()"
          [tuiHint]="hint"
          [tuiHintAppearance]="dataConsumptionState.styles.hintAppearance"
          tuiHintDirection="right"
          data-testid="button"
        >
          <div
            class="absolute text-sm h-full z-10 flex gap-4 items-center w-full py-2"
            [ngClass]="{
              'px-4': !collapsed,
              'px-2': collapsed
            }"
          >
            <tui-icon  [icon]="dataConsumptionState.icon"></tui-icon>
            <span class="flex-1 truncate" [animateFade]="!collapsed" animateDirection="left">
              {{ dataConsumptionState.label }}
            </span>
            <span class="font-bold truncate" [animateFade]="!collapsed" animateDirection="left" data-testid="consumption-detail">
              {{ percentUsed | number: '1.0-0' }} %
            </span>
          </div>
          <div
            class="z-0 absolute rounded-full h-full"
            [ngStyle]="{ width: percentUsed + '%' }"
            [ngClass]="dataConsumptionState.styles.progressClass"
            data-testid="progress"
          ></div>
        </div>
      }
      <ng-template #hint>
        {{clientDataConsumption.used | formatGoDataUnit}} consommés sur {{clientDataConsumption.total | formatGoDataUnit}}
      </ng-template>
    </ng-container>
  }
}