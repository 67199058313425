@if (channel$ | async; as channel) {
  <div class="w-full h-full flex overflow-hidden">
    <div class="w-full h-full flex flex-col relative">
      <pxc-channel-header
        [channel]="channel"
        class="w-full z-10"
        (openChannelInfo)="openSettings()"
      ></pxc-channel-header>
      <div
        class="flex flex-1 items-center justify-center bg-neutral-1 overflow-hidden"
        [ngClass]="{
          '2xl:mr-96': showSettings
        }"
        *tuiLet="(messages$ | async) as messages"
      >
        @if ((isEmpty$ | async) === false) {
          <pxc-message-list
            class="h-full w-full block z-0"
            [channel]="channel"
            [messages]="messages"
            [messageGroups]="(messageGroups$ | async)"
            [hasUnseenMessages]="(hasUnseenMessages$ | async)"
            (loadNext)="loadNext()"
            (markMessagesAsSeen)="markMessagesAsSeen()"
          ></pxc-message-list>
        } @else {
          <p class="text-secondary">Aucun message.</p>
        }
      </div>
      <pxc-message-input
        (sendMessage)="sendMessage($event)"
        class="w-full z-10"
      ></pxc-message-input>
    </div>
    <pxc-channel-settings
      [channel]="channel"
      (closeClick)="closeSettings()"
      class="transition-all h-full lg:max-2xl:shadow-xl lg:border-l lg:border-border-normal duration-300 shrink-0 absolute top-0 max-lg:left-0 right-0 bg-base-alt lg:w-96"
      [ngClass]="{
        'opacity-100 z-20 translate-x-0': showSettings,
        'opacity-0 translate-x-full': !showSettings
      }"
    ></pxc-channel-settings>
  </div>
}
