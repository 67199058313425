@switch (state.status) {
  @case (OperationState.ONGOING) {
    <pxc-status
      [state]="Status.LOADING"
      title="Création en cours"
      description="Le télésuivi est en cours de création."
      data-testid="status-loading"
    >
      <div class="flex gap-2 justify-end mt-2 w-full">
        <button tuiButton appearance="outline" (click)="closeModal()">Fermer</button>
      </div>
    </pxc-status>
  }
  @case (OperationState.FINISHED) {
    <pxc-status
      [state]="Status.SUCCESS"
      title="Succès"
      description="Le télésuivi a été créé avec succès."
      data-testid="status-success"
    >
      <div class="flex gap-2 justify-end mt-2 w-full">
        <button
          tuiButton
          appearance="secondary"
          (click)="sendTutorial()"
          data-testid="send-tutorial"
          iconStart="@tui.mail"
        >
          Envoyer le tutoriel
        </button>
        <button tuiButton (click)="closeModal()" data-testid="close-modal">
          Fermer
        </button>
      </div>
    </pxc-status>
  }
  @case (OperationState.FAILED) {
    <pxc-status
      [state]="Status.ERROR"
      title="Échec"
      [description]="state.message"
      data-testid="status-fail"
    >
      <div class="flex gap-2 justify-end mt-2 w-full">
        <button tuiButton (click)="closeModal()" data-testid="close-modal">Fermer</button>
      </div>
    </pxc-status>
  }
}
