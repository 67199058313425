<form class="flex flex-col gap-4" (submit)="confirm()" [formGroup]="formGroup">

  @if (encounters$ | async; as encounters) {
    @if (encounters.length > 0) {
      <p>
        Sélectionnez le séjour dans lequel vous souhaitez exporter le rapport du patient.
      </p>
    }
    <div 
      class="flex flex-col gap-2 overflow-y-auto min-h-[300px] max-h-[50vh]"
      scroll-tracker
      [threshold]="{bottom:100}"
      (bottomThreshold)="loadNextPage()"
      data-testid="scroll-viewport"
    >
      @for (encounter of encounters; track encounter) {
        <label tuiBlock [attr.data-testid]="encounter.id"> 
          <input 
            tuiRadio 
            type="radio"
            formControlName="encounterId"
            [value]="encounter.id"
            [attr.data-testid]="encounter.id+'-input'"
          >
          <div 
            class="flex items-center gap-2 w-full" 
            *tuiLet="(encounterStatusConfig[encounter.status] ?? unknownEncouterStatusConfig) as config"
          >
            @if (encounter.ufCode !== null || encounter.identifier !== null) {
              <div class="flex flex-col items-start gap-1 flex-1">
                <div class="flex gap-2">
                  @if (encounter.ufCode !== null) {
                    <tui-chip data-testid="encounter-uf-code">
                      UF : <strong>{{ encounter.ufCode }}</strong>
                    </tui-chip>
                  }
                  @if (encounter.identifier !== null) {
                    <tui-chip data-testid="encounter-ehr-identifier">
                      ID : <strong>{{ encounter.identifier }}</strong>
                    </tui-chip>
                  }
                </div>
                <ng-container [ngTemplateOutlet]="dateTemplate"></ng-container>
              </div>
            } @else {
              <ng-container [ngTemplateOutlet]="dateTemplate"></ng-container>
            }
            <ng-template #dateTemplate>
              <p class="flex-1" data-testid="encounter-date">
                @switch (encounter.status) {
                  @case (EncounterStatus.PLANNED) {
                    Séjour à partir du <strong>{{ encounter.fromDate | date }}</strong>
                  }
                  @case (EncounterStatus.IN_PROGRESS) {
                    Séjour depuis le <strong>{{ encounter.fromDate | date }}</strong>
                  }
                  @case (EncounterStatus.FINISHED) {
                    Séjour du <strong>{{ encounter.fromDate | date }}</strong>
                    au <strong>{{ encounter.toDate | date }}</strong>
                  }
                }
              </p>
            </ng-template>
            <tui-badge
              [appearance]="config.appearance"
              data-testid="encounter-state"
            >
              {{ config.label }}
            </tui-badge>
          </div>
        </label>
      }
      @if (encounters.length === 0) {
        <pxc-status [state]="Status.BASIC"
          class="my-auto"
          icon="@tui.search"
          title="Aucun séjour"
          description="Ce patient n'a aucun séjour enregistré dans son DPI."
          data-testid="no-encounter-message"
        ></pxc-status>
      }
    </div>
  } @else {
    <pxc-loader class="my-2" data-testid="loading">
      Chargement des séjours du patient ...
    </pxc-loader>
  }

  <div class="flex justify-between gap-2">
    <button tuiButton type="button" appearance="outline" (click)="close()" data-testid="cancel">Annuler</button>
    <button tuiButton
      type="submit"
      [disabled]="!formGroup.valid"
      data-testid="confirm"
    >
      Confirmer
    </button>
  </div>

</form>
