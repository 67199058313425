<article class="w-24 h-24 rounded overflow-hidden snap-center" #thumbnail>
    <img
        class="w-full h-full cursor-pointer transition"
        [ngClass]="{'brightness-50 hover:brightness-90': !active}"
        [src]="item.thumbnailSrc"
        alt="Image thumbnail"
        (click)="onThumbnailClicked()"
        [hidden]="!loaded"
        (load)="loaded = true"
        data-testid="image"
    >

    @if (!loaded) {
        <pxc-loader
            class="w-full h-full bg-base-alt"
            [dark]="true"
            data-testid="loading"
        ></pxc-loader>
    }
</article>