<!-- Drawer Drop List -->
@if (drawer$ | async; as drawer) {
  <div 
    class="droplist-ctrl-container hidden lg:block absolute overflow-hidden select-none"
    [ngClass]="[
      drawer.state,
      drawer.medias.length > 0 ? 'full' : 'empty'
    ]"
  >
    <div id="drawer-list"
      class="droplist-ctrl hidden lg:flex flex-col !pt-1 !px-3 !pb-0"
      [ngClass]="[
        drawer.state,
        drawer.medias.length > 0 ? 'full' : 'empty'
      ]"
      [class.dragging]="drawer.isUserDragging"
      cdkDropList
      [cdkDropListData]="drawer.medias"
      [cdkDropListEnterPredicate]="alreadyPresentPredicate"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="isUserSorting = true"
      (cdkDropListExited)="isUserSorting = false"
      data-testid="droplist"
    >
      <!-- Image List -->
      @for (media of drawer.medias; track media; let idx = $index) {
        <div 
          class="thumbnail group relative my-1 mx-0 cursor-grab first-of-type:mt-0"
          cdkDrag
        >
          @if (!drawer.isUserDragging) {
            <button
              type="button"
              tuiButtonClose
              tuiIconButton
              class="opacity-0 group-hover:opacity-100 !absolute !top-1 !right-1 transition-opacity"
              (click)="removeThumbnail(idx)"
              [attr.data-testid]="'remove-media-'+idx"
            ></button>
          }
          <img class="w-full rounded" [src]="media.thumbnailUri" alt="thumbnail">
          <div *cdkDragPlaceholder
            class="aspect-square w-full border-2 border-dashed border-border-pressed rounded-xl bg-neutral-1"
          ></div>
        </div>
      }
    </div>
  </div>
  <!-- Drawer  -->
  <div 
    class="drawer-container hidden lg:flex border border-border-normal flex-col bg-base absolute rounded-xl items-center p-4"
    [ngClass]="drawer.state"
    [class.pb-2]="drawer.state !== DrawerState.TINY"
    [class.dragging]="drawer.isUserDragging"
  >
    <!-- Handle -->
    <pxc-handle
      class="drawer-handle absolute -translate-y-full z-[1001] shadow rounded-full"
      [class.dragging]="!drawer.isUserDragging && [DrawerState.TINY, DrawerState.COMPACT].includes(drawer.state)"
      (handleClicked)="updateDrawerState()"
      data-testid="handle"
    ></pxc-handle>
    <!-- Informations -->
    <div class="flex flex-col items-center w-full flex-1 relative gap-2">
      <div class="flex gap-2 justify-center items-center">
        @if (drawer.medias.length>0) {
          <p class="bg-neutral-1-pressed py-px px-2 rounded-full" data-testid="media-count">
            {{ drawer.medias.length > 99 ? '99+' : drawer.medias.length }}
          </p>
        }
        <tui-icon
          class="z-[1001]"
          tuiTooltip="Le tiroir de comparaison vous permet de glisser vos photos puis de les comparer."
          tuiHintDirection="left"
          data-testid="tooltip-icon"
        ></tui-icon>
      </div>
      @if (!isUserSorting && ((drawer.state === DrawerState.OPEN || drawer.isUserDragging) && drawer.medias.length <= 0)) {
        <div 
          class="flex flex-col justify-center items-center text-center w-full my-auto gap-2 empty-drawer-arrow" 
          data-testid="expanded-title"
        >
          <tui-icon icon="@tui.corner-right-down"></tui-icon>
          <p class="text-sm">Glisser ici pour comparer.</p>
        </div>
      }
      @if (drawer.state === DrawerState.TINY && !drawer.isUserDragging) {
        <p
          class="text-xs text-center text-secondary" 
          data-testid="tiny-title"
        >
          Comparez vos photos
        </p>
      }
    </div>
    <!-- Bottom Buttons -->
    @if (drawer.medias.length > 0) {
      <div class="flex flex-col w-full items-stretch gap-1 my-1">
        <button
          tuiButton
          type="button"
          appearance="secondary"
          size="s"
          (click)="loadImageInGallery()"
          data-testid="compare-button"
        >
          Comparer
        </button>
        <button
          tuiButton
          type="button"
          appearance="destructive"
          size="s"
          (click)="clearItems()"
          data-testid="clear-button"
        >
          Vider
        </button>
      </div>
    }
  </div>
}