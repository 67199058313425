<button
    tuiIconButton
    appearance="flat"
    iconStart="@tui.link"
    class="!text-positive !bg-status-positive-pale hover:!bg-status-positive-pale-hover"
    data-testid="patient-quick-action"
    tuiHint="Le patient est rattaché à votre établissement."
    tuiHintDirection="right"
    tuiHintAppearance="dark"
    size="xs"
></button>