<div class="flex gap-2 items-start" [ngClass]="{
  'ml-auto': !isIncoming,
  'mt-2': showHeader
}">

  @if (isIncoming) {
    <pxc-avatar
      [name]="message.senderName"
      [avatarColors]="avatarColors"
      [ngClass]="{
        'opacity-0 h-0': !showHeader
      }"
    ></pxc-avatar>
  }

  <div
    class="flex flex-col cursor-pointer"
    [ngClass]="{'items-start': isIncoming,'items-end': !isIncoming}"
    (click)="messageClicked.emit()"
  >
    <div class="w-fit rounded-b-xl !shadow p-3 flex flex-col gap-1" [ngClass]="{
      'bg-base rounded-tr-xl': isIncoming,
      'bg-neutral-2 rounded-tl-xl': !isIncoming 
    }">

      @if (showHeader) {
        @if (isIncoming) {
          <div class="flex gap-3 justify-between">
            <p 
              class="text-sm line-clamp-1 font-medium" 
              [ngStyle]="{
                'color': avatarColors.textColor
              }"
            >
              {{ message.senderName }}
            </p>
            <ng-container *ngTemplateOutlet="messageDate"></ng-container>
          </div>
        } @else {
          <ng-container *ngTemplateOutlet="messageDate"></ng-container>
        }
      }

      <p class="max-w-prose break-words">{{ message.text }}</p>
      <pxc-message-special
        [special]="message.special"
        [userId]="authenticationService.currentUser.id"
      ></pxc-message-special>
    </div>

    <tui-elastic-container>
      @if (shouldShowReaders) {
        <p class="mt-1 text-secondary">
          {{ readers | listMessageReaders: channelUsersCount:authenticationService.currentUser.id }}
        </p>
      }
    </tui-elastic-container>
  </div>

  <ng-template #messageDate>
    <p class="text-xs text-tertiary text-right">
      {{ date | date:'shortTime' }}
    </p>
  </ng-template>

</div>
