import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ChannelMessage, ChannelUser } from '@pixacare/pxc-ts-core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { ChannelParticipantsComponent } from '../channel-participants/channel-participants.component';
import { MessageBubbleComponent } from '../message-bubble/message-bubble.component';
import { Size } from 'src/app/shared/models/enums/size.enum';
import { StatusComponent } from '../../shared/status/status.component';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { MessageSpecialService } from '../channel-pane/message-special.service';

@Component({
  selector: 'pxc-message-readers-list',
  standalone: true,
  imports: [
    CommonModule,
    ChannelParticipantsComponent,
    MessageBubbleComponent,
    StatusComponent,
  ],
  providers: [
    MessageSpecialService,
  ],
  templateUrl: './message-readers-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageReadersListComponent {

  @Input() readers = this.context.data.readers;
  @Input() message = this.context.data.message;

  Size = Size;
  Status = Status;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<void, {
      readers: ChannelUser[];
      message: ChannelMessage;
    }>,
  ) {}

}
