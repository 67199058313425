import { TuiButton } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiButtonClose } from '@taiga-ui/kit';

@Component({
  selector: 'pxc-image-overlay',
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
    TuiButtonClose,
  ],
  templateUrl: './image-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageOverlayComponent {

  @Input() caption: string;

  @Input() showDelete = true;

  @Output() delete = new EventEmitter<void>();

}
