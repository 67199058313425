import { inject } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const serverUnavailableInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const router = inject(Router);
  return next(req).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 0) {
          router.navigate(['/', 'server-unavailable'], { queryParamsHandling: 'merge' });
        }
        return throwError(() => err);
      }
    }),
  );
};
