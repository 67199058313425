<pxc-filter-bar
  data-testid="search"
  searchPlaceholder="Rechercher un mot clé"
></pxc-filter-bar>

<ng-container *tuiLet="labels$ | async as labels">

  @if (labels?.length === 0) {
    <pxc-status
      [state]="Status.BASIC"
      [title]="(search$ | async) ?? '' === '' ? 'Aucun mot clé' : 'Aucun résultat'"
      [description]="(search$ | async) ?? '' === '' ? 'Vous n\'avez pas encore créé de mot clé.' : 'Aucun mot clé ne correspond à votre recherche'"
      icon="@tui.search"
      class="mx-auto block py-6"
    ></pxc-status>
  }

  @if (labels?.length > 0) {
    <tui-scrollbar tuiSurface="outline" class="rounded-xl mt-3 overflow-hidden">
      <ng-content select="[slot=header]"></ng-content>
      <cdk-virtual-scroll-viewport
        #viewport
        tuiScrollable
        [itemSize]="56"
        [maxBufferPx]="700"
        [minBufferPx]="500"
        class="tui-zero-scrollbar h-[59vh]"
      >
        <table tuiTable [columns]="columns" class="w-full" size="m"> 
          <thead>
            <tr tuiThGroup>
              <th
                *tuiHead="'labelInstance.word'"
                tuiTh
                [sticky]="true"
                [sorter]="labelSorter"
                [style.top.px]="-viewport['_renderedContentOffset']"
              >
                Mot clé
              </th>
              @if (displayStats) {
                <th
                  *tuiHead="'data.subscribersCount'"
                  tuiTh
                  [sticky]="true"
                  [sorter]="usersSorter"
                  [style.top.px]="-viewport['_renderedContentOffset']"
                >
                  Utilisé par
                </th>
                <th
                  tuiTh
                  [sticky]="true"
                  *tuiHead="'userLabelStatsInstance.count'"
                  [sorter]="usageSorter"
                  [style.top.px]="-viewport['_renderedContentOffset']"
                >
                  Utilisations
                </th>
              }
              <th
                *tuiHead="'createdBy'"
                tuiTh
                [sticky]="true"
                [sorter]="createdBySorter"
                [style.top.px]="-viewport['_renderedContentOffset']"
              >
                Créé par
              </th>
              <th
                *tuiHead="'actions'"
                tuiTh
                [sticky]="true"
                [sorter]="null"
                [style.top.px]="-viewport['_renderedContentOffset']"
              >
              </th>
            </tr>
          </thead>
          <tbody tuiTbody *tuiLet="(labels | tuiTableSort) as sortedLabels" [data]="sortedLabels">
            <tr tuiTr *cdkVirtualFor="let label of sortedLabels" class="group hover:bg-neutral-1/20">
              <td
                *tuiCell="'labelInstance.word'"
                tuiTd
                class="min-w-72 max-w-72"
              >
                <pxc-label
                  [label]="label.labelInstance"
                  [attr.data-testid]="label.labelInstance.word"
                ></pxc-label>
              </td>
              @if (displayStats) {
                <td
                  *tuiCell="'data.subscribersCount'"
                  tuiTd
                  class="min-w-32 max-w-32"
                >
                  {{ label.data.subscribersCount | i18nPlural: personCountPluralMapping }}
                </td>
                <td
                  *tuiCell="'userLabelStatsInstance.count'"
                  tuiTd
                  class="min-w-32 max-w-32"
                >
                  @if (label.userLabelStatsInstance?.count) {
                    {{ label.userLabelStatsInstance.count | i18nPlural: usagePluralMapping }}
                  } @else {
                    Non utilisé
                  }
                </td>
              }
              <td
                *tuiCell="'createdBy'"
                tuiTd
                class="min-w-44 max-w-44"
              >
                <span class="w-full truncate">
                  @if (label.createdBy) {
                    {{ label.createdBy.firstName }} {{ label.createdBy.lastName | uppercase }}
                  } @else {
                    Utilisateur inconnu
                  }
                </span>
              </td>
              <td
                tuiTd
                *tuiCell="'actions'"
              >
                @if (actions?.length > 0) {
                  <pxc-action-buttons
                    class="md:opacity-0 transition group-hover:opacity-100"
                    [entity]="label"
                    [actions]="actions"
                    [actionButtons]="actionButtons"
                  ></pxc-action-buttons>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </cdk-virtual-scroll-viewport>
    </tui-scrollbar>
  }
</ng-container>