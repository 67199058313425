import { TuiLet } from '@taiga-ui/cdk';
import { TuiBadge, TuiChevron } from '@taiga-ui/kit';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MenuBuilderService } from 'src/app/modules/dashboard/sidebar/menu-builder.service';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { TuiNotification, TuiExpand, TuiScrollbar, TuiLink, TuiButton, TuiHint } from '@taiga-ui/core';
import { SharedModule } from '../../shared/shared.module';
import { UserAccountCardComponent } from '../../user/user-account-card/user-account-card.component';
import { AnimateFadeDirective } from '../../shared/directives/animate-fade.directive';
import { ClientPickerComponent } from '../../client/client-picker/client-picker.component';
import { DataConsumptionComponent } from '../../paywall/data-consumption/data-consumption.component';
import { SidebarService } from './sidebar.service';
import { SidebarOpenFolderService } from './sidebar-open-folder.service';
import { UntilDestroy  } from '@ngneat/until-destroy';
import { LicenseState } from '@pixacare/pxc-ts-core';
import { ChatService } from '../../chat/chat.service';
import { MessageGroupService } from '../../chat/message-group.service';
import { ChannelNamePipe } from '../../chat/pipes/channel-name.pipe';

@UntilDestroy()
@Component({
  selector: 'pxc-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MenuBuilderService,
    SidebarOpenFolderService,
    ChatService,
    MessageGroupService,
    ChannelNamePipe,
  ],
  styles: [`
    :host {
      display: block;
      height: 100%;
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TuiScrollbar,
    SharedModule,
    TuiHint,
    TuiNotification,
    TuiExpand,
    TuiLink,
    UserAccountCardComponent,
    DataConsumptionComponent,
    ClientPickerComponent,
    TuiLet,
    AnimateFadeDirective,
    TuiButton,
    TuiBadge,
    TuiChevron,
  ],
})
export class SidebarComponent implements OnInit {

  @Input() collapsed = false;

  cdnClientBaseUrl: string = null;
  defaultClientImagePath = 'default.png';

  LicenseState = LicenseState;

  openDepartment$ = this.sidebarFolderService.openDepartment$;

  constructor(
    private readonly router: Router,
    readonly menuBuilderService: MenuBuilderService,
    readonly sidebarService: SidebarService,
    private readonly sidebarFolderService: SidebarOpenFolderService,
  ) { }

  ngOnInit() {
    this.cdnClientBaseUrl = environment.cdn.clientBaseUrl;
  }

  changeActiveClient(clientCode: string, { route } = { route: 'dashboard/sequences' }): void {
    this.updateLocation(clientCode, { route });
  }

  updateLocation(clientCode: string, { route }: { route: string }): void {
    this.router.navigate(route ? [route] : [], {
      ...(!route && { queryParamsHandling: 'merge' }),
      queryParams: { cc: clientCode },
    });
  }

  toggleFolder(folderId: number, isExpanded: boolean): void {
    this.sidebarFolderService.setOpenDepartment(isExpanded ? null : +folderId);
  }

}
