import { TuiLet } from '@taiga-ui/cdk';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientModule } from '../patient/patient.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TelemonitoringListComponent } from './telemonitoring-list/telemonitoring-list.component';
import { SequenceModule } from '../sequence/sequence.module';
import { TelemonitoringSendTutorialComponent } from
  './telemonitoring-send-tutorial/telemonitoring-send-tutorial.component';
import { TelemonitoringPreviewComponent } from './telemonitoring-preview/telemonitoring-preview.component';
import { TuiLabel, TuiDropdown, TuiIcon, TuiLink, TuiButton, TuiHint, TuiSurface } from '@taiga-ui/core';
import { TuiBadge, TuiAvatar } from '@taiga-ui/kit';
import { TagInputComponent } from 'src/app/shared/components/tag-input/tag-input.component';
import { StatusComponent } from '../shared/status/status.component';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { SequenceMediaComponent } from '../sequence/sequence-media/sequence-media.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepartmentPickerComponent } from '../department/department-picker/department-picker.component';
import { UserPreviewComponent } from '../user/user-preview/user-preview.component';
import { CodeInputModule } from 'angular-code-input';
import { StopPropagationDirective } from '../shared/directives/stop-propagation.directive';
import { ArchivedBadgeComponent } from '@modules/shared/archived-badge/archived-badge.component';
import { TuiCardLarge } from '@taiga-ui/layout';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PatientModule,
    RouterModule,
    CodeInputModule,
    FormsModule,
    SequenceModule,
    ReactiveFormsModule,
    TuiButton,
    TuiLabel,
    TuiAvatar,
    ...TuiDropdown,
    ...TuiHint,
    TuiIcon,
    TuiBadge,
    TuiLet,
    DepartmentPickerComponent,
    SequenceMediaComponent,
    StatusComponent,
    SpinnerComponent,
    TagInputComponent,
    UserPreviewComponent,
    StopPropagationDirective,
    TuiLink,
    ArchivedBadgeComponent,
    TuiSurface,
    TuiCardLarge,
  ],
  declarations: [
    TelemonitoringListComponent,
    TelemonitoringSendTutorialComponent,
    TelemonitoringPreviewComponent,
  ],
  exports: [
    TelemonitoringListComponent,
    TelemonitoringPreviewComponent,
  ],
})
export class TelemonitoringModule { }
