<div class="flex flex-col gap-6" [ngClass]="{'!-mx-7': isDialog}">
  @for (section of sections; track trackBySectionId($index, section)) {
    @if (section.questions.length > 0) {
      <div class="flex flex-col gap-6" [attr.data-testid]="'section-' + section.id">
        <div>
          <p class="font-medium px-4 pb-1">{{ section.title | titlecase }}</p>
          @for (question of section.questions; track trackByQuestionTitle($index, question)) {
            @if (question.value) {
              <pxc-form-display-question
                [label]="question.title"
                value="{{ question.value | normalize }} {{ question.unit }}"
                [indicatorLevel]="alerts[question.id]?.[0]?.level"
                [showAlerts]="showAlerts"
              ></pxc-form-display-question>
            }
          }
        </div>
      </div>
    }
  }
</div>
