<div class="max-md:bg-base/90 max-md:backdrop-blur max-md:border-b max-md:border-b-slate-200 flex gap-1 h-14 px-2 items-center z-20 md:pointer-events-none">
  <button
    type="button"
    tuiIconButton
    iconStart="@tui.menu"
    appearance="flat"
    (click)="showSidebar()"
    class="md:!hidden pointer-events-auto"
  ></button>

  <button
    type="button"
    tuiIconButton
    iconStart="@tui.menu"
    appearance="flat"
    (click)="toggleSidebarCollapsed()"
    class="max-md:!hidden pointer-events-auto"
  ></button>

  <a 
    tuiIconButton
    appearance="flat"
    routerLink="/dashboard/home"
    queryParamsHandling="merge"
    class="md:!hidden pointer-events-auto"
  >
    <img src="https://cdn.pixacare.com/logo/mire.svg" class="p-1" />
  </a>

  <button
    tuiIconButton
    iconStart="@tui.circle-help"
    appearance="flat"
    tuiDropdownOpen
    [tuiDropdown]="helpMenuActions"
    class="ml-auto pointer-events-auto"
  ></button>

<ng-template #helpMenuActions>
  <pxc-menu-actions
    [actions]="helpActions"
  ></pxc-menu-actions>
</ng-template>
