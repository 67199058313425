<div class="flex gap-2 items-center max-w-full">

  <button tuiIconButton
    appearance="outline"
    (click)="previous()"
    [disabled]="selectedIndex <= 0"
    data-testid="previous"
    iconStart="@tui.chevron-left"
  ></button>

  <tui-carousel
    class="sadm-carousel"
    [itemsCount]="itemsCount$ | async"
    [(index)]="displayIndex"
  >

    @for (report of reports; track report; let idx = $index) {
      <div
        *tuiItem
        [attr.data-testid]="report.title"
        class="flex flex-col gap-2 items-center cursor-pointer rounded-xl p-2 max-w-[16vh] mx-auto transition-all border border-transparent hover:border-border-normal"
        [ngClass]="{
          'bg-neutral-1 !border-border-normal': selectedIndex === idx
        }"
        (click)="moveTo(idx)"
      >
        <img
          class="rounded-xl w-full h-full"
          (click)="openGallery(idx)"
          [src]="report.media.thumbnailUri"
          data-testid="thumbnail"
          alt="thumbnail"
        />
          <pxc-date-preview
            [date]="report.date"
            [displayTime]="false"
            class="m-1"
          ></pxc-date-preview>
        </div>
      }

    </tui-carousel>

    <button tuiIconButton
      appearance="outline"
      (click)="next()"
      [disabled]="selectedIndex >= reports.length - 1"
      data-testid="next"
      iconStart="@tui.chevron-right"
    ></button>

  </div>