<div class="mb-3">
  <pxc-filter-bar
    searchPlaceholder="Rechercher un utilisateur par nom, prénom ou email ..."
    data-testid="filter-bar"
  ></pxc-filter-bar>
</div>
@if (searchedAdministrationUsers$ | async; as searchedAdministrationUsers) {
  <pxc-users-administration-table
    [administrationUsers]="searchedAdministrationUsers"
    [actionsByUserState]="departmentUsersActions"
    (adminChanged)="adminChange($event)"
  >
    @if (department$ | async; as department) {
      <pxc-administration-table-header
        slot="header"
        title="Membres de {{department.name}}"
        counterLabel="{{(administrationUsers$ | async).length}} membres"
        [userCountByAdminState]="(administrationUsers$ | async) | CountByKey: 'state'"
        [actions]="tableHeaderActions"
        [actionButtonsGroups]="tableHeaderActionButtons"
      ></pxc-administration-table-header>
    }
  </pxc-users-administration-table>
} @else {
  <pxc-loader data-testid="loading" class="my-5">
    Chargement des utilisateurs ...
  </pxc-loader>
}

