<div tuiCardLarge tuiSurface="outline" class="flex !flex-row gap-4">
  <tui-icon icon="@tui.clipboard"></tui-icon>
  <div class="flex-1">
    <p class="font-medium">Protocole de soin</p>
    <p class="text-secondary text-sm">{{ description }}</p>
  </div>
  @if (!!protocol) {
    <button
      tuiButton
      appearance="secondary"
      iconEnd="@tui.external-link"
      size="s"
      class="self-center"
      (click)="openClick.emit()"
      data-testid="open-protocol"
    >
      Ouvrir
    </button>
  } @else {
    <button
      tuiButton
      appearance="secondary"
      iconEnd="@tui.plus"
      size="s"
      class="self-center"
      (click)="create.emit()"
      data-testid="create-protocol"
      [disabled]="sadmEntity.isArchived || (sadmEntity.patient && sadmEntity.patient.isArchived)"
    >
      Ajouter
    </button>
  }
</div>
