import { TuiAutoFocus, TuiLet } from '@taiga-ui/cdk';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { PatientContact } from '@pixacare/pxc-ts-core';
import { PatientContactHttpService } from '@services/http/patient-contact.http.service';
import { BehaviorSubject, combineLatest, defer, first, Observable, shareReplay } from 'rxjs';
import { TuiButton, TuiDataList, TuiFlagPipe, TuiLoader } from '@taiga-ui/core';
import {
  TuiDataListWrapper,
  TuiInputPhoneInternational,
  tuiInputPhoneInternationalOptionsProvider,
  TuiSortCountriesPipe,
} from '@taiga-ui/kit';
import { TuiSelectModule, TuiTextfieldControllerModule } from '@taiga-ui/legacy';
import { TuiCountryIsoCode } from '@taiga-ui/i18n/types';
import { getCountries } from 'libphonenumber-js';
import { MaskitoPipe } from '@maskito/angular';
import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import metadata from 'libphonenumber-js/max/metadata';

type OnChangeFn = (value: PatientContact) => void;

@Component({
  selector: 'pxc-patient-contact-input',
  standalone: true,
  imports: [
    CommonModule,
    TuiLet,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataList,
    TuiDataListWrapper,
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
    TuiLoader,
    TuiAutoFocus,
    TuiInputPhoneInternational,
    TuiSortCountriesPipe,
    TuiFlagPipe,
    MaskitoPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PatientContactInputComponent,
    },
    tuiInputPhoneInternationalOptionsProvider({
      metadata: defer(async () =>
        import('libphonenumber-js/max/metadata').then((m) => m.default),
      ),
      separator: ' ',
    }),
  ],
  templateUrl: './patient-contact-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientContactInputComponent implements ControlValueAccessor, OnChanges {

  @Input() patientId: number;
  @Input() clientCode: string;
  @Input() inputPlaceholder = 'Saisir un numéro de téléphone';
  @Input() selectPlaceholder = 'Sélectionner un numéro de téléphone';

  patientContactService = inject(PatientContactHttpService);

  value$ = new BehaviorSubject<PatientContact | null>(null);

  touched = false;
  disabled = false;

  items$: Observable<PatientContact[]>;
  search$ = new BehaviorSubject<string>('');

  countryIsoCode: TuiCountryIsoCode = 'FR';

  input = '';
  addMode = false;

  readonly mask = maskitoPhoneOptionsGenerator({
    metadata,
    separator: ' ',
  });

  protected readonly countries = getCountries();

  onChange: OnChangeFn = () => {};
  onTouched: () => void = () => {};

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.patientId || !changes.clientCode) {
      return;
    }

    this.items$ = this.patientContactService.getPatientContacts(this.patientId, this.clientCode).pipe(
      shareReplay(1),
    );

    this.selectFirstItem();

  }

  selectFirstItem(resetAddMode = true): void {
    combineLatest([
      this.items$,
      this.value$,
    ]).pipe(first()).subscribe(([items, value]) => {
      if (!items.length || (value && resetAddMode)) {
        this.input = value?.phoneNumber ?? '';
        if (resetAddMode) {
          this.addMode = !value?.id;
        }
        return;
      }
      this.onValueChange(items[0]);
    });
  }

  onValueChange(value: PatientContact | null): void {

    if (value === null) {
      this.value$.next(null);
      this.onChange(null);
      this.addMode = true;
      return;
    }

    this.markAsTouched();
    this.value$.next(value);
    this.onChange(value);
  }

  writeValue(value: PatientContact | null): void {
    this.value$.next(value);
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onInput(input: string): void {
    this.input = input;
    this.onValueChange({
      id: null,
      patientId: this.patientId,
      phoneNumber: input ?? '',
    });
  }

  backToList(): void {
    this.addMode = false;
    this.selectFirstItem(false);
  }

}
