@if (sequences$ | async; as sequences) {
<tui-scrollbar 
  class="h-[58vh]"
  scroll-tracker
  [threshold]="{bottom: 300}"
  (bottomThreshold)="loadNextSequences()"
  data-testid="scrollbar"
>

@if (!singleSelectMode) {

  <label tuiLabel class="mb-3"> 
    <input 
      tuiCheckbox 
      type="checkbox"
      [ngModel]="isAllSequencesSelected"
      (ngModelChange)="checkUncheckAll(sequences.data, $event)"
      data-testid="select-all-checkbox"
    />
    Tout sélectionner
  </label>
}

  <div class="flex flex-col gap-2">

    @for (sequence of sequences?.data; track trackBySequenceId($index, sequence)) {

      <ng-container *tuiLet="disabledSequenceIds.includes(sequence.sequenceInstance.id) as disabled">
        @if (!singleSelectMode) {

        <label tuiLabel class="!w-full"> 
          <input 
            tuiCheckbox 
            type="checkbox"
            [attr.data-testid]="'checkbox-' + sequence.sequenceInstance.id"
            class="w-full"
            ngDefaultControl
            [ngModel]="selectedSequenceIds.includes(sequence.sequenceInstance.id)"
            (ngModelChange)="handleCheckChange(sequence.sequenceInstance.id,sequences.data, $event)"
            [attr.data-disabled]="disabled"
            [disabled]="disabled"
          />
          <ng-container [ngTemplateOutlet]="sequenceCard"></ng-container>
        </label>
      } @else {
          <label tuiLabel class="!w-full"> 
            <input tuiRadio type="radio"
              [value]="sequence.sequenceInstance.id"
              size="m"
              [attr.data-testid]="'radio-' + sequence.sequenceInstance.id"
              class="w-full"
              ngDefaultControl
              [ngModel]="selectedSequenceIds[0]"
              (ngModelChange)="handleRadioCheck(sequence.sequenceInstance.id)"
              [attr.data-disabled]="disabled"
              [disabled]="disabled"
            />
            <ng-container [ngTemplateOutlet]="sequenceCard"></ng-container>
          </label>

        }
      </ng-container>

      <ng-template #sequenceCard>
          <pxc-sequence-preview 
            [sequence]="sequence"
            [showPatient]="showPatient"
            [mobileMode]="true"
            [patientRedirect]="false"
            [attr.data-testid]="'sequence-'+sequence.sequenceInstance.id"
            class="w-full"
          >
            @if ((badgeContent && badgeContent(sequence)); as badge) {
              <tui-notification
                size="m" 
                appearance="warning"
                icon="@tui.triangle-alert"
                [attr.data-testid]="'badge-'+sequence.sequenceInstance.id"
              >
                {{ badge }}
              </tui-notification>
            }
            <pxc-sequence-picker-linked-entities-list
              bottom-preview
              [sequence]="sequence"
              [showSadm]="showSadms"
              [showTelemonitoring]="showTelemonitorings"
              (selectTelemonitoringSequences)="selectTelemonitoringSequences($event, sequences.data)"
              (selectSadmSequences)="selectSadmSequences($event, sequences.data)"
              stopPropagation
              [attr.data-testid]="'sequence-entities-'+sequence.sequenceInstance.id"
            ></pxc-sequence-picker-linked-entities-list>
          </pxc-sequence-preview>
        </ng-template>
      }
    </div>
    @if (sequences?.data.length === 0 && !sequences?.context?.isNextPageLoading) {
      <div class="pb-6" data-testid="empty-state">
        <pxc-lottie-label
          animation="sequenceEmpty"
          message="Vous n'avez aucune photo"
        ></pxc-lottie-label>
      </div>
    }
    <div class="flex items-center justify-center py-4" data-testid="spinner">
      <pxc-spinner [isVisible]="sequences?.context?.isNextPageLoading"></pxc-spinner>
    </div>
  </tui-scrollbar>
}