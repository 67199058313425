import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TuiAlertService } from '@taiga-ui/core';
import { TokenPayload } from 'src/app/shared/models/token-payload';
import { ValidationCodeActionType } from 'src/app/shared/models/enums/validation-code-action-type.enum';
import { isTokenValid } from 'src/app/shared/utils/token-utils';
import { User } from '@pixacare/pxc-ts-core';

@Component({
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {

  rememberEmailKey = 'user-email';

  currentUser: User;
  actionOnGoing$: BehaviorSubject<boolean>;

  loginForm: UntypedFormGroup;

  intentUrl: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly localStorageService: LocalStorageService,
  ) { }

  get inputEmail(): AbstractControl {
    return this.loginForm.get('inputEmail');
  }

  get inputPassword(): AbstractControl {
    return this.loginForm.get('inputPassword');
  }

  get rememberEmail(): AbstractControl {
    return this.loginForm.get('rememberEmail');
  }

  ngOnInit(): void {

    this.currentUser = this.authenticationService.currentUser;
    this.actionOnGoing$ = new BehaviorSubject(false);

    this.loginForm = this.formBuilder.group({
      inputEmail: new UntypedFormControl(this.localStorageService.get(this.rememberEmailKey), [
        Validators.required,
        Validators.email,
        Validators.maxLength(256),
      ]),
      inputPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      rememberEmail: new UntypedFormControl(this.localStorageService.exists(this.rememberEmailKey)),
    });

    if (this.currentUser) {
      this.router.navigate(['/']);
    }

    this.intentUrl = this.route.snapshot.queryParams.intentUrl || '/';
  }

  login(): void {
    if (!this.loginForm.valid) {
      this.alertService.open('Merci de résoudre les erreurs de saisie.', {
        label: 'Erreur de validation',
        appearance: 'error',
      }).subscribe();

      return;
    }

    this.actionOnGoing$.next(true);
    this.authenticationService.login({
      mailAddress: this.inputEmail.value,
      password: this.inputPassword.value,
    }).subscribe({
      next: (token: TokenPayload) => {
        if (this.rememberEmail.value) {
          this.saveUserEmail();
        } else {
          this.removeUserEmail();
        }

        this.actionOnGoing$.next(false);

        if (!isTokenValid(token)) {
          this.router.navigate(['/user/validate'], {
            queryParamsHandling: 'merge',
            state: {
              actionType: ValidationCodeActionType.LOGIN,
            },
          });
        } else {
          this.router.navigateByUrl(this.intentUrl);
        }

      },
      error: (err: HttpErrorResponse) => {
        this.actionOnGoing$.next(false);

        let title = '';
        let message = '';
        if (err.status === 400) {
          title = 'Identifiants Incorrects';
          message = 'Les identifiants renseignés sont incorrects.';
        } else {
          console.error(err);
          title = 'Erreur Système';
          message = "Une erreur s'est produite, merci de réessayer plus tard.";
        }
        this.alertService.open(message, {
          label: title,
          appearance: 'error',
        }).subscribe();
      },
    });
  }

  saveUserEmail(): void {
    this.localStorageService.set(this.rememberEmailKey, this.inputEmail.value);
  }

  removeUserEmail(): void {
    this.localStorageService.remove(this.rememberEmailKey);
  }

}
