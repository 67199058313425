import { TuiButton } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { StatusComponent } from '@modules/shared/status/status.component';
import { Telemonitoring } from '@pixacare/pxc-ts-core';
import { DialogService } from '@services/dialog.service';
import { OperationState } from '@shared/models/enums/operation.enum';
import { Status } from '@shared/models/enums/status.enum';
import { TelemonitoringSendTutorialComponent }
  from '../telemonitoring-send-tutorial/telemonitoring-send-tutorial.component';

@Component({
  selector: 'pxc-telemonitoring-request-result',
  standalone: true,
  imports: [
    CommonModule,
    StatusComponent,
    TuiButton,
  ],
  templateUrl: './telemonitoring-request-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringRequestResultComponent {

  @Input() state: {
    message?: string;
    data?: Telemonitoring;
    status: OperationState;
  };

  @Output() closeDialog = new EventEmitter<void>();

  Status = Status;
  OperationState = OperationState;

  private readonly dialogService = inject(DialogService);

  sendTutorial(): void {

    if (!this.state.data) {
      return;
    }

    this.closeDialog.emit();
    this.dialogService.openComponent(TelemonitoringSendTutorialComponent, {
      label: 'Envoyer le tutoriel',
      data: {
        telemonitoring: this.state.data,
      },
    }).subscribe();
  }

  closeModal(): void {
    this.closeDialog.emit();
  }

}
