import { HttpRequest, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

export const tokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const authenticationService = inject(AuthenticationService);
  const jwt = authenticationService.getToken();
  if (!jwt) {
    return next(req);
  }
  const cloned = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${jwt}`),
  });
  return next(cloned);
};
