<ng-template #menuActions>
  <pxc-menu-actions
    [actions]="actions"
    [entity]="user"
  ></pxc-menu-actions>
</ng-template>

<pxc-tile 
  class="rounded-xl hover:bg-neutral-1 transition py-2 cursor-pointer w-full" 
  [ngClass]="collapsed ? 'px-2.5' : 'px-1'"
  [tuiHint]="collapsed ? 'Votre compte' : null"
  tuiHintAppearance="dark"
  tuiHintDirection="right"
  tuiDropdownOpen
  [tuiDropdown]="menuActions"
  [tuiDropdownSided]="true"
>
  <pxc-avatar 
    leading 
    [name]="user.firstName + ' ' + user.lastName"
    size="tiny"
    class="transition-all"
  ></pxc-avatar>
  <p class="line-clamp-1 break-all text-sm" title [animateFade]="!collapsed">{{user.firstName}} {{user.lastName}}</p>
  <p class="line-clamp-1 break-all text-secondary text-xs" subtitle [animateFade]="!collapsed">{{user.mailAddress}}</p>
  <tui-icon  icon="@tui.ellipsis-vertical" class="text-tertiary" [animateFade]="!collapsed" trailing></tui-icon>
</pxc-tile>