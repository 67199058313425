import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DocumentService } from 'src/app/services/document.service';
import { first } from 'rxjs/operators';
import { ActionsService } from 'src/app/shared/models/helpers/actions-service';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { PatientTab } from 'src/app/shared/models/enums/patient-tabs.enum';
import { selectTelemonitoring } from 'src/app/shared/store/telemonitorings/telemonitorings.selectors';
import { telemonitoringsActions } from 'src/app/shared/store/telemonitorings/telemonitorings.actions';
import { MenuActionId } from 'src/app/shared/models/menu-actions/menu-action-id.enum';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { DialogService } from 'src/app/services/dialog.service';
import { TelemonitoringHttpService } from 'src/app/services/http/telemonitoring.http.service';
import { TelemonitoringSendTutorialComponent }
  from './telemonitoring-send-tutorial/telemonitoring-send-tutorial.component';
import { SequenceReportComponent } from '../sequence/sequence-report/sequence-report.component';
import { FilteredSequenceReportConfig } from 'src/app/shared/models/report-config';
import { AuthorizationsService } from 'src/app/services/authorizations.service';
import { SequencePickerDialogComponent, SequencePickerOutput }
  from '../sequence/sequence-picker/sequence-picker-dialog/sequence-picker-dialog.component';
import { Telemonitoring } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { isEverythingTrue$ } from '@shared/utils/utils';
import { selectIsPatientActive } from '@shared/store/patients/patients.selectors';

@Injectable({
  providedIn: 'root',
})
export class TelemonitoringActionsService extends ActionsService {

  actions: MenuAction<Telemonitoring>[] = [
    {
      id: MenuActionId.TELEMONITORING_TUTORIAL,
      label: 'Tutoriel',
      icon: '@tui.file',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => this.isPatientActive$(telemonitoring),
      execute: (telemonitoring: Telemonitoring): void => {
        this.documentService.downloadDocument({
          name: telemonitoring.name || 'Tutoriel de télésuivi',
          document$: this.telemonitoringService.getTutorial(+telemonitoring.id),
        });
      },
    },
    {
      id: MenuActionId.TELEMONITORING_SEND_TUTORIAL,
      label: 'Envoyer Tutoriel',
      icon: '@tui.mail',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.dialogService.openComponent(TelemonitoringSendTutorialComponent, {
          label: 'Envoyer le tutoriel',
          data: {
            telemonitoring,
          },
        }).subscribe();
      },
    },
    {
      id: MenuActionId.TELEMONITORING_REPORT,
      label: 'Rapport',
      icon: '@tui.file-text',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => this.isPatientActive$(telemonitoring),
      execute: (telemonitoring: Telemonitoring): void => {

        this.dialogService.openComponentWithCloseConfirmation
        <SequenceReportComponent, FilteredSequenceReportConfig>(SequenceReportComponent, {
          label: 'Sélectionner les séquences à exporter',
          data: {
            patientId: telemonitoring.patientId,
            clientCode: telemonitoring.clientCode,
            telemonitoringId: telemonitoring.id,
            departmentIds: telemonitoring.departmentIds,
          },
          size: 'l',
        }).subscribe((reportConfig) => {

          if (reportConfig) {
            this.documentService.downloadDocument({
              name: telemonitoring.name || 'Rapport de télésuivi',
              document$:
                this.telemonitoringService.exportTelemonitoring(+telemonitoring.id, reportConfig.sequenceIds, {
                  includeCharts: reportConfig.includeCharts,
                  includeReports: reportConfig.includeReports,
                }),
            });
          }

        });
      },
    },
    {
      id: MenuActionId.TELEMONITORING_OPEN_CHAT,
      label: 'Ouvrir la conversation',
      icon: '@tui.message-square',
      condition: (telemonitoring: Telemonitoring): boolean => telemonitoring.channelId !== null,
      execute: (telemonitoring: Telemonitoring): void => {
        this.router.navigate(['/dashboard/chat', telemonitoring.channelId], {
          queryParamsHandling: 'merge',
        });
      },
    },
    {
      id: MenuActionId.TELEMONITORING_LINK_SEQUENCES,
      label: 'Lier des séquences',
      icon: '@tui.link',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringActive(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {

        this.store.select(selectTelemonitoring(telemonitoring.id))
          .pipe(first((tm) => !!tm.sequences))
          .subscribe((tm) => {

            const preselectedSequences = tm.sequences;

            const selectedSequenceMessage = 'Cette séquence est déjà attribuée à un télésuivi.'
            + " Si vous la réattribuez, elle sera retirée de l'autre télésuivi.";

            this.dialogService.openComponentWithCloseConfirmation
            <SequencePickerDialogComponent, SequencePickerOutput>(SequencePickerDialogComponent, {
              data: {
                patientId: telemonitoring.patientId,
                clientCode: telemonitoring.clientCode,
                departmentIds: telemonitoring.departmentIds,
                preselectedSequences,
                disabledSequenceIds: preselectedSequences.map((s) => s.sequenceInstance.id),
                badgeContent: (sequence) => {
                  const tmId = sequence.sequenceInstance.telemonitoringId;

                  if (tmId && tmId !== telemonitoring.id) {
                    return selectedSequenceMessage;
                  }
                  return null;
                },
              },
              label: 'Sélectionnez les séquences à lier au télésuivi',
              size: 'l',
            }).subscribe((result) => {

              if (result) {
                const preselectedIds = preselectedSequences.map((s) => s.sequenceInstance.id);
                const filteredSequenceIds = result.selectedSequenceIds?.filter(
                  (id) => !preselectedIds.includes(id),
                );

                if (filteredSequenceIds.length > 0) {
                  this.store.dispatch(telemonitoringsActions.linkSequences({
                    telemonitoringId: +telemonitoring.id,
                    clientCode: telemonitoring.clientCode,
                    sequenceIds: filteredSequenceIds,
                  }));
                }
              }

            });

          });

      },
    },
    {
      id: MenuActionId.TELEMONITORING_ARCHIVE,
      label: 'Archiver',
      icon: '@tui.archive',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringActive(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.store.dispatch(telemonitoringsActions.updateTelemonitoringActiveState({
          telemonitoringId: telemonitoring.id,
          isActive: false,
        }));
      },
    },
    {
      id: MenuActionId.TELEMONITORING_ENABLE,
      label: 'Activer',
      icon: '@tui.archive',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringArchived(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.store.dispatch(telemonitoringsActions.updateTelemonitoringActiveState({
          telemonitoringId: telemonitoring.id,
          isActive: true,
        }));
      },
    },
    {
      id: MenuActionId.TELEMONITORING_DELETE,
      label: 'Supprimer',
      icon: '@tui.trash',
      status: Status.ERROR,
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringArchived(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.dialogService.openConfirm('Êtes-vous certain de vouloir supprimer ce télésuivi ?',
          {
            content: 'Cette action est irréversible.',
            yes: 'Supprimer',
          }).subscribe((confirm) => {
          if (confirm) {
            this.store.dispatch(
              telemonitoringsActions.deleteTelemonitoring({
                telemonitoringId: +telemonitoring.id,
              }),
            );
            this.router.navigate(['/dashboard/patients', telemonitoring.patientId], {
              queryParamsHandling: 'merge',
              queryParams: { tab: PatientTab.TELEMONITORINGS },
            });
          }
        });
      },
    },
  ];

  constructor(
    public readonly store: Store,
    private readonly router: Router,
    private readonly telemonitoringService: TelemonitoringHttpService,
    private readonly documentService: DocumentService,
    private readonly authorizationsService: AuthorizationsService,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  isPatientActive$(telemonitoring: Telemonitoring): Observable<boolean> {
    return this.store.select(selectIsPatientActive(telemonitoring.clientCode, telemonitoring.patientId));
  }

  isUserOwner(telemonitoring: Telemonitoring): boolean {
    return this.authorizationsService.getUserId() === telemonitoring.createdBy;
  }

  isTelemonitoringActive(telemonitoring: Telemonitoring): boolean {
    return telemonitoring.isActive;
  }

  isTelemonitoringArchived(telemonitoring: Telemonitoring): boolean {
    return !telemonitoring.isActive;
  }

}
