import { TuiBadge } from '@taiga-ui/kit';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { SharedModule } from '../../shared/shared.module';
import { UserStateCountComponent } from '../user-state-count/user-state-count.component';
import { UserCountByAdminState } from 'src/app/shared/models/user-count-by-admin-state';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { TuiTitle } from '@taiga-ui/core';

@Component({
  selector: 'pxc-administration-table-header',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiTitle,
    TuiBadge,
    UserStateCountComponent,
  ],
  templateUrl: './administration-table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationTableHeaderComponent {

  @Input() title: string;
  @Input() counterLabel: string;
  @Input() userCountByAdminState: UserCountByAdminState;
  @Input() actions: MenuAction[];
  @Input() actionButtonsGroups: ActionButton[];

}
