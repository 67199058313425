@if (storageSpace$ | async; as storageSpace) {
  @if (!closed) {
    <div class="container mx-auto px-4 lg:px-8">
      @if (storageSpace === StorageSpace.LOW && showStorageWarningNotification === true) {
        <tui-notification
          size="m" 
          icon="@tui.triangle-alert"
          appearance="warning"
          (close)="close()"
          (closeEvent)="showStorageWarningNotification = false"
          class="mb-3"
          data-testid="low-storage-alert"
        ><div>
          Votre espace de stockage est bientôt saturé.
          <ng-container *ngTemplateOutlet="callToActionPaywallMail"></ng-container>
        </div> <button tuiIconButton iconStart="@tui.x"></button></tui-notification>
      }
      @if (storageSpace === StorageSpace.FULL) {
        <tui-notification
          size="m" 
          icon="@tui.triangle-alert"
          appearance="error"
          class="mb-3"
          data-testid="no-storage-alert"
        >
          <div>
            Votre espace de stockage est saturé.
            <ng-container *ngTemplateOutlet="callToActionPaywallMail"></ng-container>
          </div>
        </tui-notification>
      }
      <ng-template #callToActionPaywallMail>
        <a (click)="manageStorage()" tuiLink>Gérer le stockage.</a>
      </ng-template>
    </div>
  }
}