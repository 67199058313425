<div class="py-3 flex flex-col gap-4">
  <div class="my-5 flex flex-col xl:flex-row xl:items-center gap-6 animate-in fade-in slide-in-from-top-1">
    <div class="flex-1">
      <h1 tuiTitle="l" class="font-medium">
        Bonjour {{ currentUser.firstName }},
        <span tuiSubtitle>Bienvenue sur votre espace sécurisé</span>
      </h1>
    </div>

    <div class="flex flex-wrap flex-row items-center gap-3">

      <button tuiButton appearance="outline" routerLink="/dashboard/profile/details" queryParamsHandling="merge" data-testid="profile-button" iconStart="@tui.pencil">
        Modifier mon profil
      </button>
      <button tuiButton (click)="createSequence()" data-testid="create-sequence" iconStart="@tui.plus" class="max-xl:order-first">
        Créer une séquence
      </button>
    </div>
  </div>

  <div tuiCardLarge tuiSurface="outline" class="flex flex-col gap-3 animate-in fade-in slide-in-from-bottom-1">
    <h5 tuiTitle="m">Mes statistiques</h5>
    @if (userStatistics$ | async; as userStatistics) {
      <div class="grid lg:grid-cols-3 items-stretch gap-4">
        @for (item of stats; track item) {
          <div
            class="flex items-center p-5 lg:p-6 gap-4 rounded-xl cursor-pointer transition-all bg-opacity-50 hover:bg-opacity-100 hover:shadow-xl hover:-translate-y-1 group active:brightness-90"
            [ngClass]="{
              'pixablue': 'bg-pixablue-3 text-pixablue-1 !shadow-blue-100/50',
              'pixapurple': 'bg-pixapurple-3 text-pixapurple-1 !shadow-purple-100/50',
              'pixared': 'bg-pixared-3 text-pixared-1 !shadow-red-100/50'
            }
            [item.color]"
            [routerLink]="item.link"
            [queryParams]="{cc: clientCode$ | async}"
            queryParamsHandling="merge"
            [attr.data-testid]="item.label"
          >
            <div class="flex-1 flex flex-col gap-2">
              <p class="leading-tight">{{ item.label }} </p>
              <div class="flex items-center gap-2">
                <tui-icon [icon]="item.icon" class="h-8 w-8"></tui-icon>
                <p class="text-3xl tracking-tight">{{ userStatistics[item.value] }}</p>
              </div>
            </div>
            <tui-icon  icon="@tui.chevron-right" class="opacity-50 group-hover:opacity-100"></tui-icon>
          </div>
        }
      </div>
    } @else {
      <pxc-loader></pxc-loader>
    }
  </div>

  <div class="my-5 flex flex-col gap-2 animate-in fade-in slide-in-from-top-1">
    <h5 tuiTitle="m">
      Besoin d'aide ?
      <span tuiSubtitle>Notre équipe est à votre disposition pour vous aider.</span>
    </h5>
    <div class="flex flex-col md:flex-row md:items-center gap-6 mt-2">
      @for (item of helpActions; track item) {
        <ng-container *ngTemplateOutlet="helpItem; context: item"></ng-container>
      }
    </div>
  </div>

</div>

<ng-template #helpItem let-icon="icon" let-label="label" let-execute="execute">
  <a
    tuiLink
    (click)="execute()"
    target="_blank"
    appearance="flat"
    class="flex items-center gap-2"
  >
    <tui-icon [icon]="icon" class="h-5 w-5"></tui-icon>
    {{ label }}
  </a>
</ng-template>

