import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { LogoutStrategyService } from '@services/logout-strategy.service';

export const mustChangePasswordGuard: CanActivateFn = () => {
  const authenticationService = inject(AuthenticationService);
  const logoutStrategyService = inject(LogoutStrategyService);

  if (!!authenticationService.currentUser && !authenticationService.currentUser.mustChangePassword) {
    return true;
  }

  logoutStrategyService.logout();
  return false;
};

