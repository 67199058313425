<div class="flex justify-start align-center">
  @for (button of buttons$ | async; track button; let index = $index) {
    <button
      tuiButton
      appearance="custom"
      size="s"
      class="!min-w-20 tui-space_right-2"
      [ngClass]="index === selectedIndex ? '!bg-neutral-2' : '!bg-neutral-1'"
      (click)="onButtonClick(button, index)"
    >
      {{ button.display }}
    </button>
  }
</div>
