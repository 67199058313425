<div id="canvas-tools"
  [ngClass]="{'hidden':isPictureLoading}"
  class="flex justify-center items-center h-full"
></div>
<div class="flex flex-col justify-center items-center gap-1 p-2 shadow-xl bg-base rounded-xl absolute left-2.5 top-1/2 -translate-y-1/2">
  @for (tool of studioTools; track tool; let index = $index) {
    <button
      tuiIconButton
      [ngClass]="tool.classes"
      [appearance]="selectedToolName === tool.commandName ? 'opposite' : 'flat'"
      [iconStart]="tool.icon"
      [tuiHint]="getTooltipString(tool.name, tool.shortcut)"
      tuiHintDirection="right"
      tuiHintAppearance="dark"
      (click)="onToolClick(tool.commandName)"
    ></button>
  }
  <button 
    class="group flex p-1 bg-transparent border-none outline-none"
    tuiDropdownOpen
    [tuiDropdown]="colorPicker"
    tuiDropdownAlign="right"
    [tuiDropdownSided]="true"
    [tuiDropdownOffset]="12"
  >
    <div class="flex relative justify-center items-center rounded-full border-border-normal border-2 border-solid"
      [style.width.px]="colorPickerConfig.displayWidth"
      [style.height.px]="colorPickerConfig.displayWidth"
    >
      <div class="absolute rounded-full inset-1/2 -translate-x-1/2 -translate-y-1/2 border border-solid border-neutral-1"
        [style.background-color]="drawConfig.color.code"
        [style.width.px]="getWidthDisplay()"
        [style.height.px]="getWidthDisplay()"
      ></div>
    </div>
  </button>
</div>

<ng-template #colorPicker>
  <pxc-color-picker
    [(color)]="drawConfig.color"
    [(width)]="drawConfig.width"
    [colorPickerConfig]="colorPickerConfig"
  ></pxc-color-picker>
</ng-template>

<div class="flex items-center justify-center absolute w-full top-1 left-0 h-12 ">
  @if (selectedToolName === tool.PREPARE_CROP) {
    <div
      class="shadow-xl gap-2 bg-base rounded-xl relative flex items-center p-2 h-full ml-auto translate-x-1/2"
    >
      <tui-icon 
        class="m-1"
        icon="@tui.crop"
      ></tui-icon>
      <span class="bg-neutral-1-pressed w-px h-1/2"></span>
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.undo"
        tuiHint="Rétablir"
        tuiHintDirection="bottom"
        tuiHintAppearance="dark"
        (click)="resetCrop()"
      ></button>
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.circle-x"
        tuiHint="Annuler (Echap)"
        tuiHintDirection="bottom"
        tuiHintAppearance="dark"
        (click)="onToolClick(tool.DEFAULT_TOOL)"
      ></button>
      <button
        tuiIconButton
        appearance="flat"
        iconStart="@tui.circle-check"
        tuiHint="Valider (Entrer)"
        tuiHintDirection="bottom"
        tuiHintAppearance="dark"
        (click)="executeCrop()"
      ></button>
    </div>
  }

  <div class="shadow-xl bg-base rounded-xl flex flex-row p-1 ml-auto m-2">
    <button
      tuiIconButton
      appearance="flat"
      iconStart="@tui.download"
      tuiHint="Télécharger l'image"
      tuiHintDirection="bottom"
      tuiHintAppearance="dark"
      (click)="downloadPicture()"
    ></button>
    <button
      tuiIconButton
      appearance="flat"
      iconStart="@tui.x"
      tuiHint="Quitter"
      tuiHintDirection="bottom"
      tuiHintAppearance="dark"
      (click)="closeStudio()"
    ></button>
  </div>
</div>

@if (isPictureLoading) {
  <pxc-loader
    class="my-auto"
  >
    Chargement de l'image ...
  </pxc-loader>
}

<ng-template #closeConfirm let-observer>
  <p>
    Si vous quittez le studio sans enregistrer, vous perdrez toutes les modifications actuelles.
  </p>
  <div class="flex max-md:flex-col items-stretch md:items-center gap-2 mt-4">
    <button
      tuiButton
      appearance="destructive"
      (click)="observer.complete(); this.closeWithoutSaving();"
      class="md:mr-auto"
    >
      Ne pas enregistrer
    </button>
    <button
      tuiButton
      appearance="outline"
      (click)="observer.complete()"
    >
      Annuler
    </button>

    <button
      tuiButton
      [loading]="isSaveOngoing"
      appearance="primary"
      (click)="this.saveAndClose(observer)"
    >
      Enregistrer
    </button>

  </div>
</ng-template>