import { Component, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { BehaviorSubject, first } from 'rxjs';
import { Patient } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-patient-create',
  templateUrl: './patient-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCreateComponent {

  @Input() clientCode: string = this.context.data.clientCode;
  isPatientCreationFormValid$ = new BehaviorSubject<boolean>(false);
  patient: Patient;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<Patient, Partial<PatientCreateComponent>>,
  ) { }

  createPatient(): void {

    this.isPatientCreationFormValid$.pipe(first()).subscribe((isPatientFormValid) => {

      if (!isPatientFormValid) {
        return;
      }
      this.context.completeWith({ ...this.patient });
    });
  }

  cancel(): void {
    this.context.completeWith(null);
  }

}
