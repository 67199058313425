<article
  tuiCardLarge
  tuiSurface="outline"
  *tuiLet="(mobileMode || (isMobile$ | async)) as isMobile"
  class="select-none !p-0 overflow-hidden"
  [ngClass]="{
    '!bg-patient-gradient': sequence.sequenceInstance.creatorType === CreatorType.BY_PATIENT
  }"
>
  <div class="flex flex-col divide-y divide-slate-100 px-3 py-1">

    <header class="flex justify-between flex-wrap gap-2 items-center py-1.5">

      <p class="text-sm text-secondary text-balance flex items-center gap-1">
        <span class="font-medium">
          {{ sequence.sequenceInstance.createdOn | date: 'mediumDate' | uppercase }}
        </span>
        à {{ sequence.sequenceInstance.createdOn | date: 'shortTime' }}
        <span class="mx-1 text-border-normal">•</span>
        @if (sequence.createdByInstance && sequence.sequenceInstance.creatorType === CreatorType.BY_USER) {
          <pxc-user-preview
            [user]="sequence.createdByInstance" [colored]="false">
          </pxc-user-preview>
        }
        @if (sequence.sequenceInstance.creatorType === CreatorType.BY_PATIENT) {
          <span class="inline-flex justify-center items-center !h-5 !w-5 overflow-hidden rounded-xl bg-purple-200 mx-0.5">
            <tui-icon class="h-4 w-4 pt-0.5 mt-0.5 text-purple-900" [icon]="'@tui.user'"></tui-icon>
          </span>
          par le patient
        }
      </p>

      <div class="flex flex-wrap gap-3 justify-end items-center text-sm ml-auto">
        @if (sequence.sequenceInstance.description) {
          <a
            tuiLink
            (click)="openSequenceDescription(descriptionDialog)"
            data-testid="description"
            data-appearance="primary"
            class="flex items-center gap-1.5"
          >
            Description
          </a>
        }
        <ng-content></ng-content>
        @if (actions) {
          <pxc-action-buttons
            *showIfUserAuthorizedForSequence="sequence.sequenceInstance"
            [actions]="actions"
            [entity]="sequence"
            optionButtonLabel="Options"
          ></pxc-action-buttons>
        }
      </div>
    </header>

    <div
      class="flex gap-2 py-2.5"
      [ngClass]="{'flex-col': isMobile}"
    >

      <!-- Patient -->
      @if (showPatient) {
        <div class="flex-1">
          @if (!!sequence.patientInstance && patientRedirect) {
            <a [patientLink]="sequence.patientInstance.id">
              <ng-container [ngTemplateOutlet]="patientDisplay"></ng-container>
            </a>
          } @else {
            <ng-container [ngTemplateOutlet]="patientDisplay"></ng-container>
          }
        </div>
      }

      <!-- Labels -->
      <tui-scrollbar class="min-h-0 max-h-24 xl:max-h-36 flex-1">
        <div class="flex gap-1 flex-wrap">
          @for (label of sequence.labels; track label) {
            <pxc-label [label]="label"></pxc-label>
          }
        </div>
      </tui-scrollbar>

      <!-- Medias -->
      <div class="flex-1 max-w-md lg:px-6">
        <pxc-sequence-preview-media-carousel
          [sequenceId]="sequence.sequenceInstance.id"
          [medias]="sequence.medias"
          [clientCode]="sequence.clientCode"
          (mediaClicked)="mediaClicked.emit($event)"
        ></pxc-sequence-preview-media-carousel>
      </div>

      <ng-content select="[bottom-preview]"></ng-content>

    </div>

  </div>

  <!-- Description dialog -->
  <ng-template #descriptionDialog let-observer>
    <div class="flex flex-col gap-4">
      <p class="whitespace-pre-line">
        {{ sequence.sequenceInstance.description }}
      </p>
      <div class="flex gap-2 justify-end">
        <button
          tuiButton
          appearance="outline"
          (click)="observer.complete()"
        >
          Fermer
        </button>
      </div>
    </div>

  </ng-template>

  <!-- Patient display -->
  <ng-template #patientDisplay>
    <div class="flex gap-2">
      <pxc-patient-avatar
        [patient]="sequence.patientInstance"
        size="small"
      ></pxc-patient-avatar>
      <pxc-patient-data
        data-testid="patient"
        [patient]="sequence.patientInstance"
        class="max-w-full"
        size="small"
      ></pxc-patient-data>
    </div>
  </ng-template>

</article>

