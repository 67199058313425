import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { StorageService } from 'src/app/services/storage.service';
import { StorageSpace } from 'src/app/shared/models/paywall/storage-space.enum';
import { DataConsumptionComponent } from '../data-consumption/data-consumption.component';
import { CommonModule, NgIf } from '@angular/common';
import { TuiNotification, TuiLink } from '@taiga-ui/core';

@Component({
  selector: 'pxc-paywall-alert',
  templateUrl: './paywall-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiNotification,
    TuiLink,
    NgIf,
  ],
})
export class PaywallAlertComponent implements OnInit {

  storageSpace$: Observable<StorageSpace>;
  showStorageWarningNotification = true;
  StorageSpace = StorageSpace;

  closed = false;

  constructor(
    private readonly storageService: StorageService,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.storageSpace$ = this.storageService.getActiveClientStorageSpace();
  }

  manageStorage(): void {
    this.dialogService.openComponent(DataConsumptionComponent, {
      label: 'Gérer votre stockage',
      data: { detailedMode: true },
    }).subscribe();
  }

  close(): void {
    this.closed = true;
  }

}
