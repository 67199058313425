<div class="flex flex-col gap-3">

  <pxc-message-bubble
    [message]="message"
    [showHeader]="true"
    data-testid="message-bubble"
  ></pxc-message-bubble>

  <p>Lu par</p>

  @if (readers.length > 0) {
    <pxc-channel-participants
      [participants]="readers"
      data-testid="readers"
    ></pxc-channel-participants>
  } @else {
    <pxc-status
      [state]="Status.BASIC"
      icon="@tui.eye"
      [showTitle]="false"
      description="Personne n'a lu le message !"
      data-testid="empty-state"
    ></pxc-status>
  }

</div>
