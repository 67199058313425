@if ((user && !isCurrentUser)) {
  <a
    routerLink="/dashboard/chat"
    [queryParams]="{
      'userId': user.id, 
      'firstName': user.firstName, 
      'lastName': user.lastName
    }"
    queryParamsHandling="merge"
    class="
      hover:!underline focus:!underline
      underline-offset-4
      hover:brightness-80 
      focus:brightness-70
      transition-all
    "
    data-testid="link"
  >
    <ng-container *ngTemplateOutlet="userDisplay"></ng-container>
  </a>
} @else {
  <ng-container *ngTemplateOutlet="userDisplay"></ng-container>
}

<ng-template #userDisplay>
  <span
    [ngStyle]="{'color': colored && avatarColors.textColor}"
    [ngClass]="{'text-[inherit]': !colored}"
  >
    {{ user?.firstName }} {{ user?.lastName }}
  </span>
</ng-template>
