<div class="!shadow-none 
  transition active:bg-neutral-1 rounded-lg"
  [ngClass]="{'ring-2 ring-accent-1': isSelected}"
>
  @if (sadmEntity.previewMedia && !imageError) {
    <img
      class="h-48 w-full rounded-xl object-cover"
      data-testid="image"
      [src]="sadmEntity.previewMedia.thumbnailUri"
      [alt]="sadmEntity | sadmEntityName: sadmType | titlecase"
      (error)="onImageLoadingError()"
    >
  } @else {
    <pxc-image-broken
      class="block h-48 w-full rounded-xl aspect-square overflow-hidden"
    ></pxc-image-broken>
  }
  <p class="mt-2 leading-tight">
    {{ sadmEntity | sadmEntityName | titlecase }}
  </p>
  <p class="text-secondary text-sm mt-1">
    {{ sadmEntity | sadmEntityType: sadmType | firstLetterUppercase  }}
  </p>
  <p class="text-tertiary text-sm" data-testid="date">
    {{ createdOn | date }}
  </p>
</div>

