<div class="w-full h-full">
  <div class="flex overflow-auto flex-col-reverse h-full p-4 gap-3 scroll-smooth"
    #scrollBar
    scroll-tracker
    [threshold]="{top: topThreshold, bottom: bottomThreshold}"
    (bottomThreshold)="bottomReached()"
    (topThreshold)="topReached()"
  >
    @for (messageGroup of messageGroups; track trackByMessageGroup(groupIndex, messageGroup); let groupIndex = $index) {
      <div class="flex flex-col-reverse gap-1">
        @for (messageId of messageGroup.messageIds; track trackByMessageId(messageIndex, messageId); let messageIndex = $index) {
          <pxc-message-bubble
            [message]="messages[messageId]"
            [showHeader]="messageGroup.options?.[messageId]?.showSender ?? false"
            [readers]="(groupIndex === 0 && messageIndex === 0) ? lastMessageReaders : null"
            [channelUsersCount]="channel.users.length"
            (messageClicked)="openReadersList(messages[messageId])"
          ></pxc-message-bubble>
        }
        <div class="sticky top-4 left-1/2 -translate-x-1/2 px-3 py-1 mt-2 bg-neutral-1 rounded-xl w-fit">
          <p class="text-secondary text-sm font-medium text-center">
            {{ messageGroup.date | date:'longDate' }}
          </p>
        </div>
      </div>
    }
  </div>

  <button
    tuiButton
    appearance="primary"
    class="fixed left-1/2 -translate-x-1/2 shadow-xl transition-all duration-300"
    [ngClass]="{
      'opacity-0 pointer-events-none bottom-0': !hasUnseenMessages,
      'opacity-100 bottom-14': hasUnseenMessages
    }"
    iconEnd="@tui.arrow-down"
    (click)="scrollToBottom()"
  >
    Nouveaux messages
  </button>

</div>