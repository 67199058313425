<div 
  class="h-24 p-3 text-base flex justify-center items-center backdrop-blur bg-accent-opposite/50 rounded-xl"
>
  <div class="flex justify-center items-center h-full px-4 gap-4 divide-x divide-slate-600/40">
    <pxc-date-preview
      [date]="date"
      [displayTime]="false"
    ></pxc-date-preview>
    @if (isOpen) {
      <pxc-patient-data
        customClass="text-base"
        [patient]="patient"
        data-testid="patient-data"
        class="px-3"
      ></pxc-patient-data>
    }
  </div>
  <pxc-handle
    class="absolute right-0 translate-x-1/2"
    [handleState]="false"
    [direction]="HandleDirection.HORIZONTAL"
    (handleClicked)="toggle()"
    data-testid="card-handle"
  ></pxc-handle>
</div>