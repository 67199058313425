import { TuiTextfieldControllerModule, TuiSelectModule } from '@taiga-ui/legacy';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { PatientForm } from '@pixacare/pxc-ts-core';
import { DepositHttpService } from '@services/http/deposit.http.service';
import { TuiDataListWrapper, TuiStringifyContentPipe, TuiFilterByInputPipe } from '@taiga-ui/kit';
import { shareReplay } from 'rxjs';

type OnChangeFn = (value: PatientForm) => void;

@Component({
  selector: 'pxc-form-select',
  standalone: true,
  imports: [
    CommonModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataListWrapper,
    FormsModule,
    ReactiveFormsModule,
    TuiStringifyContentPipe,
    TuiFilterByInputPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormSelectComponent,
    },
  ],
  templateUrl: './form-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectComponent implements ControlValueAccessor {

  @Input() placeholder = 'Selectionnez un questionnaire';

  disabled = false;
  touched = false;
  value: PatientForm;

  readonly items$ = inject(DepositHttpService).getForms().pipe(shareReplay(1));

  onChange: OnChangeFn = () => {};
  onTouched: () => void = () => {};

  writeValue(obj: PatientForm): void {
    this.value = obj;
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  stringify(item: PatientForm): string {
    return item.form?.title;
  }

  onSelectChange(value: PatientForm): void {
    this.touched = true;
    this.onChange(value);
  }

}
