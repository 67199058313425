import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { SequencePickerService } from '../sequence-picker.service';
import { CommonModule } from '@angular/common';
import { SequencePickerLinkedEntityComponent }
  from '../sequence-picker-linked-entity/sequence-picker-linked-entity.component';
import { TuiLet } from '@taiga-ui/cdk';

@Component({
  selector: 'pxc-sequence-picker-linked-entities-list',
  templateUrl: './sequence-picker-linked-entities-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SequencePickerLinkedEntityComponent,
    TuiLet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencePickerLinkedEntitiesListComponent {

  @Input() sequence: FlattenedSequence;

  @Input() showTelemonitoring = false;
  @Input() showSadm = false;

  @Output() selectSadmSequences: EventEmitter<number> = new EventEmitter();
  @Output() selectTelemonitoringSequences: EventEmitter<number> = new EventEmitter();

  readonly sequencePickerService = inject(SequencePickerService);

}
