<pxc-tile class="px-3 py-2 border-b border-border-normal">
  <pxc-channel-avatar
    leading
    *showIfDesktop="{
      elseTemplate: backButton
    }"
    [channelType]="channel?.type"
    [channelUsers]="channel?.users"
    data-testid="channel-avatar"
  ></pxc-channel-avatar>

  <p title class="text-lg line-clamp-2">{{channel | channelName}}</p>

  @if (channel?.users) {
    <button
      subtitle
      type="button"
      class="text-secondary hover:text-secondary transition w-fit"
      (click)="openChannelInfo.emit()"
      data-testid="channel-participants"
    >
      {{ channel.users?.length | i18nPlural: participantsPluralMapping }}
      <tui-icon icon="@tui.chevron-right"></tui-icon>
    </button>
  }

</pxc-tile>

<ng-template #backButton>
  <a
    leading
    tuiIconButton
    appearance="flat"
    iconStart="@tui.arrow-left"
    routerLink=".."
    queryParamsHandling="merge"
  ></a>
</ng-template>
