@switch (channelType) {
  @case (ChannelType.DEPARTMENT) {
    <ng-container
      [ngTemplateOutlet]="iconLogo"
      [ngTemplateOutletContext]="{iconName: '@tui.folder'}">
    </ng-container>
  }
  @case (ChannelType.TELEMONITORING) {
    <ng-container
      [ngTemplateOutlet]="iconLogo"
      [ngTemplateOutletContext]="{iconName: 'telemonitoring'}">
    </ng-container>
  }
  @default {
    <ng-container *tuiLet="channelUsers | chatHideMyself as users">
      @if (users?.length === 1) {
        <pxc-avatar
          [name]="users[0].firstName + ' ' + users[0].lastName"
          [size]="size"
          [attr.data-testid]="'avatar-' + users[0].id"
          [ngClass]="{
            'w-12 h-12': size === Size.MEDIUM || size === Size.SMALL,
            'w-20 h-20': size === Size.LARGE
          }"
          class="flex justify-center items-center"
        ></pxc-avatar>
      } @else {
        <div class="relative" [ngClass]="{
          'w-12 h-12': size === Size.MEDIUM || size === Size.SMALL,
          'w-20 h-20': size === Size.LARGE
        }">
        @for (user of users; track user; let idx = $index) {
          @if (idx < 3) {
            <pxc-avatar
              [name]="user.firstName + ' ' + user.lastName"
              [size]="size === Size.MEDIUM || size === Size.SMALL ? Size.TINY : Size.SMALL"
              class="absolute border-2 border-base-alt rounded-full"
              [ngClass]="{
                'top-0 left-0 z-10': idx === 0,
                'top-1.5 left-1.5 z-20': idx === 1,
                'top-3 left-3 z-30': idx === 2
              }"
              [attr.data-testid]="'avatar-' + user.id"
            ></pxc-avatar>
          }
        }
        </div>
      }
    </ng-container>
  }
}

<ng-template #iconLogo let-iconName="iconName">
  <div
    class="rounded-full bg-base flex items-center justify-center" 
    [ngClass]="{
      'w-12 h-12': size === Size.SMALL,
      'w-14 h-14': size === Size.MEDIUM,
      'w-20 h-20': size === Size.LARGE
    }"
  >
    <tui-icon [icon]="iconName" data-testid="icon"></tui-icon>
  </div>
</ng-template>