<tui-elastic-container *tuiLet="areLabelsValid() as areLabelsValid">

  <pxc-paywall-alert></pxc-paywall-alert>

  @if (sequence$ | async; as sequence) {
    <div class="flex flex-col gap-6">
      <section class="flex flex-col shrink-0 gap-2">
        <h6 class="font-medium">Photographies</h6>
        <pxc-image-input
          [medias]="medias"
          (mediaDelete)="deleteMedia($event)"
          [showDelete]="(medias.length + newMedias.length) > 0"
          [(ngModel)]="newMedias"
          data-testid="medias"
          (mediaClickedIdx)="openGallery($event)"
          ngDefaultControl
        ></pxc-image-input>
        @if (pictureCount === 0) {
          <p class="text-status-negative font-medium">Veuillez ajouter au moins une photo.</p>
        }
      </section>
      @if (!!basePatient && (displayAnalysis$ | async)) {
        <section class="flex flex-col gap-2">
          <h6 class="font-medium">Questionnaire</h6>
          @if ((isSadmFormLoading$ | async) === false) {
            <pxc-sadm-form-input
              [analysisFormAnswers]="analysisFormAnswers$ | async"
              (analysisFormAnswersChange)="analysisFormAnswers$.next($event)"
              [protocolFormAnswers]="protocolFormAnswers$ | async"
              (protocolFormAnswersChange)="protocolFormAnswers$.next($event)"
              [(sadmEntity)]="sadmEntity"
              [(sadmEntityId)]="sadmEntityId"
              [clientCode]="clientCode"
              [patientId]="basePatient.id"
            ></pxc-sadm-form-input>
          } @else {
            <div class="flex gap-2 items-center justify-center h-14">
              <pxc-spinner></pxc-spinner>
              <p class="text-secondary text-sm">Chargement du questionnaire ...</p>
            </div>
          }
        </section>
      }
      <section class="flex flex-col gap-2">
        <h6 class="font-medium">Patient</h6>
        <pxc-patient-edit
          [patient]="updatedPatient"
          [clientCode]="clientCode"
          [displayActionButtons]="false"
          (patientChange)="patientChange($event)"
          (formValidityUpdate)="isPatientEditFormValid$.next($event)"
          (isCheckingFormValidityChange)="isPatientEditFormLoading$.next($event)"
          data-testid="patient-edit"
        ></pxc-patient-edit>
      </section>
      <section class="flex flex-col gap-2">
        <h6 class="font-medium">Mots clés</h6>
        <pxc-select-label class="select-label"
          [(selectedLabels)]="updatedLabels"
          data-testid="labels"
        ></pxc-select-label>
        @if (!areLabelsValid) {
          <p class="text-status-negative">Vous devez entrer au moins un mot clé.</p>
        }
      </section>
      <section class="flex flex-col gap-2">
        <h6 class="font-medium">Informations</h6>
        <div class="grid lg:grid-cols-2 gap-6">
          <label tuiLabel>
            Date
            <tui-input-date-time 
              [(ngModel)]="updatedDate" 
              data-testid="input-date" 
              class="flex-1"
              [tuiTextfieldLabelOutside]="true"
            >
              Entrez une date et une heure
              <input
                tuiTextfieldLegacy
                placeholder="18/04/2019 14:22"
              />
            </tui-input-date-time>
          </label>
        </div>
        <label tuiLabel>
          Description
          <tui-textarea
            [(ngModel)]="updatedDescription"
            [expandable]="true"
            maxlength="65535"
            data-testid="description"
          >
            Ajoutez une description
          </tui-textarea>
        </label>
      </section>
      <div class="flex gap-2 items-center justify-between mt-2 flex-wrap">
        <button tuiButton type="button" appearance="outline" (click)="close()" class="max-md:w-full">Annuler</button>
        <button tuiButton type="button"
          class="max-md:w-full max-md:order-first"
          (click)="save()"
          [loading]="isPatientEditFormLoading$ | async"
          [disabled]="
            (isPatientEditFormValid$ | async) === false 
            || (isPatientEditFormLoading$ | async) 
            || !areLabelsValid 
            || pictureCount === 0"
          data-testid="confirm"
        >
          Enregistrer
        </button>
      </div>
    </div>
  }
</tui-elastic-container>
