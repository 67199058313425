import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GalleryItem } from '@shared/models/gallery-item';
import { GalleryThumbnailComponent } from '../gallery-thumbnail/gallery-thumbnail.component';
import { TuiScrollbar } from '@taiga-ui/core';

@Component({
  selector: 'pxc-gallery-thumbnail-list',
  standalone: true,
  imports: [
    CommonModule,
    GalleryThumbnailComponent,
    TuiScrollbar,
  ],
  templateUrl: './gallery-thumbnail-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryThumbnailListComponent {

  @Input() items: GalleryItem[] = [];
  @Input() activeIdx = 0;
  @Output() thumbnailClicked = new EventEmitter<number>();

}
