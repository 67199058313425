<p class="text-sm text-secondary">Emplacement</p>
<div [formGroup]="form">
	<pxc-department-picker
		[isClientChoiceDisabled]="true"
		[clientCode]="clientCode"
		[departmentIds]="departmentIds"
		(departmentIdsChange)="setDepartmentIds($event)"
		data-testid="department-picker"
	>
		<tui-error
			formControlName="departmentIds"
			[error]="[] | tuiFieldError | async"
		></tui-error>
	</pxc-department-picker>
</div>
<p class="text-secondary text-sm mt-4">Les membres des groupes sélectionnés pourront consulter le télésuivi et rejoindre la conversation à tout moment.</p>