import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Channel } from '@pixacare/pxc-ts-core';
import { ChannelAvatarComponent } from '../channel-avatar/channel-avatar.component';
import { SharedModule } from '../../shared/shared.module';
import { participantsPluralMapping } from 'src/app/shared/utils/plural-mappings';
import { TuiIcon, TuiButton } from '@taiga-ui/core';
import { ShowIfDesktopDirective } from '../../../directives/show-if-desktop.directive';
import { RouterModule } from '@angular/router';
import { TileComponent } from 'src/app/shared/components/tile/tile.component';
import { ChannelNamePipe } from '../pipes/channel-name.pipe';

@Component({
  selector: 'pxc-channel-header',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ChannelAvatarComponent,
    TuiIcon,
    ShowIfDesktopDirective,
    TuiButton,
    RouterModule,
    TileComponent,
    ChannelNamePipe,
  ],
  templateUrl: './channel-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelHeaderComponent {

  @Input() channel: Channel;
  @Output() openChannelInfo = new EventEmitter<void>();

  participantsPluralMapping = participantsPluralMapping;

}
