<div
  class="w-full aspect-square rounded-lg object-cover overflow-hidden relative group"
>
  @if (showDelete) {
    <button
      type="button"
      tuiButtonClose
      tuiIconButton
      class="!absolute top-1 group-hover:top-2 right-2 z-10 opacity-0 group-hover:opacity-90 transition-all hover:!opacity-100 hover:brightness-110"
      (click)="delete.emit()"
      data-testid="delete-button"
    ></button>
  }
  @if (caption) {
    <div class="absolute -bottom-1 opacity-0 w-full bg-gradient-to-t from-primary/80 via-primary/50 via-60% p-2 pt-8 group-hover:bottom-0 group-hover:opacity-100 transition-all">
      <p class="text-xs line-clamp-2 text-base-alt break-all">{{ caption }}</p>
    </div>
  }
  <ng-content></ng-content>
</div>