import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClientImageComponent } from '../../shared/client-image/client-image.component';
import { TuiTitle } from '@taiga-ui/core';

@Component({
  selector: 'pxc-client-title-header',
  standalone: true,
  imports: [
    CommonModule,
    ClientImageComponent,
    TuiTitle,
  ],
  templateUrl: './client-title-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientTitleHeaderComponent {

  @Input() clientCdnImagePath: string;
  @Input() title: string;
  @Input() subtitle: string;

}
