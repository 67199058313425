import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SadmEntity, SadmProtocol, SadmProtocolCreation } from '@pixacare/pxc-ts-core';
import { SadmHttpService } from '@services/http/sadm.http.service';
import { sadmActions } from '@shared/store/sadm/sadm.actions';
import { TuiAlertService } from '@taiga-ui/core';
import { catchError } from 'rxjs';

@Injectable()
export class SadmProtocolsService {

  constructor(
    private readonly sadmHttpService: SadmHttpService,
    private readonly store: Store,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) {}

  static getLatestProtocol(sadmEntity: SadmEntity): SadmProtocol {
    if (!sadmEntity?.protocolsHistory || !sadmEntity?.protocolsHistory.length) {
      return null;
    }
    return sadmEntity.protocolsHistory[0];
  }

  static getProtocolDescription(protocol: SadmProtocol): string {
    const questionId = 'protocol';

    // todo : limit text length
    // todo support others protocol types
    return protocol.protocolCreationFormAnswer.answers[questionId] as string;

  }

  createProtocol(data: SadmProtocolCreation): void {

    const loadingToastr = this.alertService.open('Le protocole est en cours de création', {
      label: 'Ajout en cours',
      appearance: 'info',
      autoClose: 10000,
      closeable: false,
    }).subscribe();

    this.sadmHttpService.createProtocol(data)
      .pipe(
        catchError((err) => {
          loadingToastr.unsubscribe();
          console.error('Error while creating protocol', err);
          this.alertService.open('Une erreur est survenue lors de la création du protocole', {
            label: 'Impossible de créer le protocole',
            appearance: 'error',
          }).subscribe();
          return [];
        }),
      )
      .subscribe((protocol) => {

        if (protocol && Object.keys(protocol).length) {

          this.store.dispatch(sadmActions.unloadSadmDashboard({
            sadmEntityId: data.sadmEntityId,
          }));

          loadingToastr.unsubscribe();

          this.alertService.open('Le protocole a été créé avec succès', {
            label: 'Protocole ajouté',
            appearance: 'success',
          }).subscribe();

        }

      });
  }

}
