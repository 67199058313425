import { TuiChip } from '@taiga-ui/kit';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'pxc-archived-badge',
  standalone: true,
  imports: [
    CommonModule,
    TuiChip,
  ],
  templateUrl: './archived-badge.component.html',
})
export class ArchivedBadgeComponent {

  @Input() size = 'xs';
  @Input() value = 'Archivé';

}
