<div class="flex flex-wrap">
  @if (sadmsEntities.length > 0) {
    @for (sadmEntity of sadmsEntities; track sadmEntity) {
      <div class="w-full sm:w-1/1 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" >
        <pxc-sadm-entity-preview
          [sadmEntity]="sadmEntity"
          [sadmType]="sadmType"
          class="cursor-pointer"
          [routerLink]="['/dashboard/sadms/', sadmEntity.id]"
          queryParamsHandling="merge"
          [attr.data-testid]="sadmEntity.id"
        ></pxc-sadm-entity-preview>
      </div>
    }
  } @else {
    <p class="text-secondary text-center my-2" data-testid="no-sadm">
      Vous n'avez aucune {{ SadmTypeLabel[sadmType] }} pour ce patient.
    </p>
  }
</div>
