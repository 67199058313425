import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Encounter, EncounterStatus, PaginationQuery } from '@pixacare/pxc-ts-core';
import { TuiDialogContext, TuiAlertService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { catchError, map, Observable, of } from 'rxjs';
import { PatientHttpService } from 'src/app/services/http/patient.http.service';
import { VirtualScrollService } from 'src/app/services/virtual-scroll.service';
import {
  encounterStatusConfig,
  unknownEncouterStatusConfig,
} from 'src/app/shared/models/encounters/encounter-status.config';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { QueryReportConfig } from 'src/app/shared/models/report-config';

@Component({
  templateUrl: './patient-encounter-picker.component.html',
  providers: [VirtualScrollService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientEncounterPickerComponent implements OnInit {

  patientId: number = this.context.data.patientId;
  clientCode: string = this.context.data.clientCode;
  patientReportConfig: QueryReportConfig = this.context.data.patientReportConfig ?? {
    query: new PaginationQuery({ orderBy: ['created_on|desc'] }),
  };

  encounters$: Observable<Encounter[]>;

  Status = Status;
  EncounterStatus = EncounterStatus;
  encounterStatusConfig = encounterStatusConfig;
  unknownEncouterStatusConfig = unknownEncouterStatusConfig;

  readonly formGroup = new UntypedFormGroup({
    encounterId: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    private patientService: PatientHttpService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    private virtualScrollService: VirtualScrollService<Encounter>,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<string, {
      patientId: number;
      clientCode: string;
      patientReportConfig?: QueryReportConfig;
    }>,
  ) { }

  ngOnInit() {
    this.encounters$ = this.virtualScrollService.init(
      this.patientService.getPatientEncounters(this.clientCode, this.patientId).pipe(
        map((encounters) =>
          encounters.sort((firstEncounter, secondEncounter) =>
            secondEncounter.fromDate.getTime() - firstEncounter.fromDate.getTime()),
        ),
        catchError(() => {
          this.alertService.open('Veuillez réessayer plus tard', {
            label: 'Impossible de récupérer les séjours du patient',
            appearance: 'error',
          }).subscribe();
          this.close();
          return of([]);
        }),
      ),
    );
  }

  loadNextPage(): void {
    this.virtualScrollService.loadNextPage();
  }

  close(): void {
    this.context.completeWith(null);
  }

  confirm(): void {

    const value = this.formGroup.get('encounterId').value;

    if (!value) {
      return;
    }

    this.context.completeWith(value);

  }

}
