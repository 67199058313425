<article 
  tuiCardLarge 
  tuiSurface="outline" 
  class="flex flex-col divide-y divide-slate-100 flex-1 !gap-2.5"
>

  <header class="flex flex-wrap justify-between items-center gap-2">

    <p class="text-sm text-secondary text-balance" data-testid="date-author">
      <span class="font-medium">{{telemonitoring.telemonitoring.createdOn | date: 'mediumDate' | uppercase}}</span> à
      {{ telemonitoring.telemonitoring.createdOn | date: 'shortTime' }}
      <span class="mx-1 text-border-normal">•</span>
      @if (telemonitoring.createdByInstance) {
        <pxc-user-preview [user]="telemonitoring.createdByInstance"
          [colored]="false">
        </pxc-user-preview>
      }
    </p>

    <div class="flex flex-wrap gap-3 !text-sm" stopPropagation>
      <button
        type="button"
        tuiLink
        data-appearance="primary"
        data-testid="qr-code"
        (click)="openQrCodeInGallery()">
        QR Code
      </button>
      @if (actions) {
        <pxc-action-buttons
          [entity]="telemonitoring.telemonitoring"
          [actions]="actions"
          data-testid="actions"
          optionButtonLabel="Options"
        ></pxc-action-buttons>
      }
    </div>
  </header>

  <div class="flex max-sm:flex-col gap-3 pt-2.5" *tuiLet="telemonitoring.telemonitoring.sequenceIds !== null as hasSequenceIds">

    <div class="shrink-0 w-20 h-20">
      @if (!telemonitoring.thumbnailUri && !hasSequenceIds) {
        <div
          class="w-full h-full tui-skeleton"
        ></div>
      } @else {
        <pxc-sequence-media
          [src]="telemonitoring.thumbnailUri"
          [clientCode]="telemonitoring.telemonitoring.clientCode"
          data-testid="thumbnail"
        ></pxc-sequence-media>
      }
    </div>

    <div class="grid lg:grid-cols-2 gap-3 flex-1">

      <div class="flex flex-col pt-1">
        <p class="line-clamp-2 leading-snug font-medium text-lg">
          {{ name }}
        </p>
        @if (!telemonitoring.telemonitoring.isActive || telemonitoring.patient.isArchived) {
          <pxc-archived-badge></pxc-archived-badge>
        }
        @if (hasSequenceIds) {
          <p class="text-secondary text-sm" data-testid="sequences-count">
            {{ telemonitoring.telemonitoring.sequenceIds?.length | i18nPlural : sequencesCountPluralMapping }}
          </p>
        } @else {
          <div class="tui-skeleton w-36 h-4"></div>
        }
        @if (telemonitoring.telemonitoring.lastActivity; as lastActivity) {
          <pxc-last-update-badge
            [lastUpdate]="lastActivity"
            class="mt-1"
          ></pxc-last-update-badge>
        }
      </div>

      @if (displayPatient) {
        <div class="flex gap-3">
          <pxc-patient-avatar [patient]="telemonitoring.patient" data-testid="avatar" class="shrink-0" size="small"></pxc-patient-avatar>
          <pxc-patient-data [patient]="telemonitoring.patient" data-testid="patient" class="grow" size="small"></pxc-patient-data>
        </div>
      }
    </div>

  </div>

</article>
