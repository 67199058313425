import { CanActivateFn, Router, ActivatedRouteSnapshot } from '@angular/router';
import { combineLatest, map, of } from 'rxjs';
import { AuthorizationsService } from '../services/authorizations.service';
import { inject } from '@angular/core';
import { GuardInitializerService } from '../services/guard-initializer.service';

export const isUserClientAdminGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {

  const authorizationService = inject(AuthorizationsService);
  const guardInitializerService = inject(GuardInitializerService);
  const router = inject(Router);

  const clientCode = route.queryParams.cc;

  if (!clientCode) {
    console.error('Client code is missing');
    router.navigate(['/dashboard'], { queryParamsHandling: 'merge' });
    return of(false);
  }

  return guardInitializerService.executeOrWaitForStoreInitialization$(
    combineLatest([
      authorizationService.isClientAdminParam$(clientCode),
      authorizationService.hasSupportAccess(),
    ]).pipe(
      map(([isClientAdmin, hasSupportAccess]) => {
        if (!isClientAdmin && !hasSupportAccess) {
          router.navigate(['/dashboard'], { queryParamsHandling: 'merge' });
          return false;
        }
        return true;
      }),
    ),
  );
};

