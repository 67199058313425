import {
  Component, Inject, OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TuiAlertService, TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import {
  Observable,
  ReplaySubject,
  Subject,
  map,
} from 'rxjs';
import { BaseSadmEntity, FormAnswers, Label, Patient } from '@pixacare/pxc-ts-core';
import { AutoCompleteLabelModel } from 'src/app/shared/models/helpers/auto-complete-label-model';
import { isLabel } from 'src/app/shared/models/helpers/label-helper';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { formatToFullDate } from 'src/app/shared/utils/utils';
import { CloseConfirmableDialogComponent }
  from '../../shared/close-confirmable-dialog/close-confirmable-dialog.component';
import { selectSadmClients } from 'src/app/shared/store/sadm/sadm.selectors';
import { SadmEntityCreationService } from '../../sadm/sadm-entity-creation.service';
import { appendToFormData } from 'src/app/shared/utils/form-data-utils';
import { SequenceHttpService } from 'src/app/services/http/sequence.http.service';
import { SequenceCreateStep } from 'src/app/shared/models/enums/sequence-create-step.enum';

@Component({
  templateUrl: './sequence-create.component.html',
  providers: [
    SadmEntityCreationService,
  ],
})
export class SequenceCreateComponent extends CloseConfirmableDialogComponent<boolean> implements OnInit {

  clientCode: string = this.context.data.clientCode;
  patient?: Patient = this.context.data.patient;
  departmentIds: number[] = this.context.data.departmentIds ?? [];
  telemonitoringId?: number = this.context.data.telemonitoringId;
  sadmEntityId?: number = this.context.data.sadmEntityId;
  sadmEntity?: BaseSadmEntity = this.context.data.sadmEntity;
  analysisFormAnswers?: FormAnswers = this.context.data.analysisFormAnswers;
  protocolFormAnswers?: FormAnswers = this.context.data.protocolFormAnswers;
  step?: SequenceCreateStep = this.context.data.step ?? SequenceCreateStep.INFORMATION;

  isPatientEditFormValid$ = new ReplaySubject<boolean>(1);
  SequenceCreateStep = SequenceCreateStep;
  pageIndex = this.step;
  errors: string[] = [];

  // General
  sequenceDataDate: Date = new Date();

  // Patient
  selectedPatient: Patient;

  // Medias
  sequenceMedias: File[] = [];

  // Analysis
  displayAnalysis$: Observable<boolean>;
  selectedSadmEntityId: number = this.sadmEntityId;
  createdSadmEntity = this.sadmEntity;
  createdAnalysisFormAnswers = this.analysisFormAnswers;
  createdProtocolFormAnswers = this.protocolFormAnswers;

  // Labels
  selectedLabels: AutoCompleteLabelModel[] = [];
  description: string;

  // Device
  deviceType = 'WEB';
  dropZoneAcceptValidator = 'image/jpeg,image/jpg,image/png';

  constructor(
    private readonly store: Store,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean, {
      clientCode: string;
      patient?: Patient;
      departmentIds?: number[];
      telemonitoringId?: number;
      shouldAskConfirmation$?: Subject<boolean>;
      sadmEntityId?: number;
      sadmEntity?: BaseSadmEntity;
      analysisFormAnswers?: FormAnswers;
      protocolFormAnswers?: FormAnswers;
      step?: SequenceCreateStep;
    }>,
  ) {
    super(context);
  }

  ngOnInit(): void {

    if (this.patient) {
      this.selectedPatient = this.patient;
    }

    this.displayAnalysis$ = this.store.select(selectSadmClients)
      .pipe(map((sadmClients) => !!this.analysisFormAnswers || Object.values(sadmClients).length > 0));

  }

  validateForm(): boolean {
    this.errors = [];

    if (this.selectedLabels.length === 0) {
      this.errors.push('Veuillez sélectionner au moins un mot clé');
    }

    if (this.sequenceMedias.length === 0) {
      this.errors.push('Veuillez ajouter au moins une photographie');
    }

    this.errors.forEach((error) => {
      this.alertService.open(error, {
        label: 'Vérifiez les informations',
        appearance: 'error',
      }).subscribe();
    });

    return this.errors.length === 0;
  }

  previous(): void {
    this.pageIndex--;
  }

  next(): void {
    this.pageIndex++;
    this.shouldCloseConfirmChange(true);
  }

  cancel(): void {
    this.context.completeWith(false);
  }

  appendSelectedPatientToFormData(formData: FormData): void {
    appendToFormData(formData, {
      patientId: this.selectedPatient.id?.toString(),
      patientBusinessIdentifier: this.selectedPatient.businessIdentifier,
      patientFirstName: this.selectedPatient.firstName,
      patientLastName: this.selectedPatient.lastName,
      patientBirthName: this.selectedPatient.birthName,
      patientBirthDate: formatToFullDate(this.selectedPatient.birthDate),
      isPatientFromGam: (this.selectedPatient.isGamLinked ?? false).toString(),
    });
  }

  generateFormData(): FormData {

    const data = new FormData();

    const naturalLabels = this.selectedLabels
      .filter((selectedLabel) => isLabel(selectedLabel.value))
      .map((selectedLabel) => (selectedLabel.value as Label).id.toString());

    const createdLabels = this.selectedLabels
      .filter((selectedLabel) => !isLabel(selectedLabel.value))
      .map((selectedLabel) => selectedLabel.value.toString());

    appendToFormData(data, {
      clientCode: this.clientCode,
      createdOn: formatToFullDate(this.sequenceDataDate),
      naturalLabels,
      createdLabels,
      sequenceImages: this.sequenceMedias,
      telemonitoringId: this.telemonitoringId,
      description: this.description,
      deviceType: this.deviceType,
    });

    // Departments
    if (!this.telemonitoringId) {
      appendToFormData(data, {
        departmentIds: this.departmentIds,
      });
    }

    // Patient
    if (this.selectedPatient) {
      this.appendSelectedPatientToFormData(data);
    }

    // Analysis
    if ((this.selectedSadmEntityId || this.createdSadmEntity) && this.createdAnalysisFormAnswers) {

      SequenceHttpService.appendAnalysisToFormData(data, {
        sadmEntity: this.createdSadmEntity,
        sadmEntityId: this.selectedSadmEntityId,
      });

      appendToFormData(data, {
        formAnswer: JSON.stringify({
          answers: this.createdAnalysisFormAnswers,
        }),
        ...(this.createdProtocolFormAnswers && {
          protocolCreationFormAnswer: JSON.stringify({
            answers: this.createdProtocolFormAnswers,
          }),
        }),
      });

    }

    return data;
  }

  create(): void {
    if (!this.validateForm()) {
      return;
    }

    this.store.dispatch(sequencesActions.synchronizeSequence({
      data: this.generateFormData(),
    }));

    this.context.completeWith(true);

  }

}
