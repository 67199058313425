<div class="flex flex-col gap-4">

  <tui-stepper [(activeItemIndex)]="step" class="flex gap-2">
    <button tuiStep>Détails</button>
    <button tuiStep>Séquences</button>
  </tui-stepper>

  @if (displayStepperContent$ | async) {
    @if (step === PatientTeleexpertiseStep.INFORMATIONS) {
      <div class="flex flex-col gap-2">
        <pxc-patient-card
          [patient]="patient"
          data-testid="patient"
        ></pxc-patient-card>
        <label tuiLabel data-testid="label-message">
          Message personnalisé
          <tui-textarea
            [(ngModel)]="message"
            data-testid="input-message"
            [tuiTextfieldLabelOutside]="true">
            Saisir un message personnalisé
          </tui-textarea>
        </label>
      </div>
    }
    @if (step === PatientTeleexpertiseStep.SEQUENCES) {
      <pxc-sequence-picker-preview
        title="Sélectionner les séquences à ajouter à la téléexpertise"
        [(sequences)]="sequences"
        (mediaClicked)="mediaClicked($event)"
        [patientId]="patient.id"
        [clientCode]="clientCode"
        [sequenceContext]="SequenceContext.TELEEXPERTISE"
        [departmentIds]="departmentIds"
      ></pxc-sequence-picker-preview>
    }
    <div class="flex gap-2 md:justify-between max-md:flex-col-reverse">
      <button
        tuiButton
        appearance="flat"
        (click)="previous()"
        type="button"
      >
        {{ step === PatientTeleexpertiseStep.INFORMATIONS ? 'Annuler' : 'Retour' }}
      </button>
      <button
        tuiButton
        appearance="primary"
        (click)="next()"
        type="button"
      >
        {{ step === PatientTeleexpertiseStep.SEQUENCES ? 'Créer' : 'Suivant' }}
      </button>
    </div>
  } @else {
    <pxc-loader data-testid="loading" class="my-5">
      Téléexpertise avec Omnidoc en cours...
    </pxc-loader>
  }
</div>
