<div class="flex flex-col gap-2 pb-4">
  @if (client$ | async; as client) {
    <pxc-client-title-header
      class="mb-3"
      [title]="client.fullName"
      subtitle="Administration"
      [clientCdnImagePath]="client.cdnImagePath"
    ></pxc-client-title-header>
  }

  <nav tuiTabs class="my-2">
    @for (tab of tabs; track tab) {
      <button
        tuiTab
        [routerLink]="tab.link"
        [routerLinkActive]="['active']"
        queryParamsHandling="merge"
        [attr.data-testid]="tab.link"
      >
        <tui-icon  [icon]="tab.icon" class="mr-1"></tui-icon>
        {{ tab.title }}
      </button>
    }
  </nav>

  <router-outlet></router-outlet>

</div>