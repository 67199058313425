<div class="flex flex-col p-4 lg:p-8">
  <pxc-back-navigation-button
    class="block mb-4 w-fit"
    label="Retour à la liste"
    data-testid="back-button"
    defaultUrl="/dashboard/"
  ></pxc-back-navigation-button>

  @if (sadmEntity$ | async; as sadmEntity) {
    <ng-container *tuiLet="(isArchived$ | async) as isArchived">
      <div class="flex justify-between items-center w-full animate-in fade-in slide-in-from-top-1">
        <div class="flex justify-start items-center gap-2 pb-2">
          <h1 tuiTitle="m">
            Bilan d'évolution {{ sadmEntity | sadmEntityName: sadmEntity.sadmClient.sadmType}}
          </h1>
          @if (isArchived) {
            <pxc-archived-badge></pxc-archived-badge>
          }
        </div>
        <pxc-action-buttons [entity]="sadmEntity" [actions]="actions"></pxc-action-buttons>
      </div>
      @if (sadmEntity.sequences !== null) {
        @if ((dashboard$ | async); as dashboard) {
          <div class="flex flex-col xl:flex-row gap-3 h-[80vh] max-xl:mb-4 animate-in fade-in"
            [ngClass]="{'xl:items-start': dashboard.dashboardConfig.dashboard.display !== Display.WIDE}">
            <div class="flex flex-col gap-4 xl:w-2/5 xl:h-full">
              @if (sadmEntity?.patient) {
                <pxc-patient-card
                  [patient]="sadmEntity.patient"
                  [routerLink]="['/dashboard/patients', sadmEntity.patient.id]"
                  queryParamsHandling="merge"
                  class="cursor-pointer"
                  data-testid="patient-card"
                ></pxc-patient-card>
              }
              @if (sadmEntity.patient && sadmEntity.patient.isArchived) {
                <pxc-archived-notification
                  title="Le patient de cette analyse est archivé"
                  description="Vous ne pouvez plus modifier ou ajouter de données à ce patient.">
                </pxc-archived-notification>
              }
              @if (sadmEntity.sadmClient?.protocolCreationFormId) {
                <pxc-sadm-entity-protocol-card
                  [sadmEntity]="sadmEntity"
                  [sadmClient]="sadmEntity.sadmClient"
                  (openClick)="openProtocols()"
                  (create)="createProtocol()"
                  data-testid="protocol-card"
                ></pxc-sadm-entity-protocol-card>
              }
              @if (dashboard.dashboardConfig.summary.display === Display.LEFT) {
                <div class="flex-1">
                  <div tuiCardLarge tuiSurface="outline" class="px-0">
                    <ng-container [ngTemplateOutlet]="formSummary"></ng-container>
                  </div>
                </div>
              }
            </div>
            <div class="flex flex-col gap-2 flex-1">
              @if (dashboard.dashboardConfig.dashboard.display === Display.WIDE) {
                @if (sadmEntity.sequences?.length > 0) {
                  <div tuiCardLarge tuiSurface="outline"  class="flex-1 overflow-y-auto h-full">
                    <div
                      class="flex flex-col lg:grid grid-system xl:h-full"
                      [ngClass]="dashboard.dashboardConfig.dashboard.classes"
                      data-testid="charts"
                    >
                      @for (chart of dashboard.charts; track chart) {
                        <div class="relative overflow-hidden"
                          [ngClass]="chart.config.classes">
                          <canvas baseChart class="max-lg:min-h-[150px]"
                            [id]="chart.config.id"
                            [data]="chart.config.data"
                            [options]="chart.config.options"
                            [plugins]="chart.config.plugins"
                            [type]="chart.config.type"
                            (chartClick)="onChartClick($event)"
                            (chartHover)="onChartHover($event)"
                          ></canvas>
                        </div>
                      }
                    </div>
                  </div>
                } @else {
                  <div class="flex flex-col gap-8 items-center h-full flex-1 justify-center">
                    <tui-icon icon="@tui.pie-chart" class="h-8 w-8 text-tertiary"></tui-icon>
                    <p class="text-secondary">Aucun graphique disponible</p>
                  </div>
                }
              }
              @if (dashboard.dashboardConfig.summary.display === Display.WIDE) {
                <div tuiCardLarge tuiSurface="outline"  class="px-0">
                  <ng-container [ngTemplateOutlet]="formSummary"
                  [ngTemplateOutletContext]="{fullScreenMode: true}"></ng-container>
                </div>
              }
            </div>
          </div>
          <ng-template #formSummary let-fullScreenMode="fullScreenMode">
            @if (sadmEntity.sequences?.length > 0) {
              <pxc-sadm-entity-form-summary
                class="h-full"
                [reports]="dashboard.formReports"
                [sadmEntityId]="sadmEntity.id"
                [clientCode]="sadmEntity.clientCode"
                [(currentIndex)]="currentFormSummaryIndex"
                data-testid="form-summary"
              >
                <button
                  tuiButton
                  appearance="outline"
                  iconEnd="@tui.plus"
                  (click)="addReport()"
                  size="s"
                  data-testid="add-form"
                  [disabled]="isArchived"
                >
                  Ajouter
                </button>
                <button
                  tuiButton
                  appearance="outline"
                  iconEnd="@tui.pencil"
                  (click)="editReport()"
                  size="s"
                  data-testid="edit-form"
                  [loading]="editAnalysisButtonLoading$ | async"
                  [disabled]="isArchived"
                >
                  Modifier
                </button>
              </pxc-sadm-entity-form-summary>
            } @else {
              <div class="flex flex-col gap-2 items-center justify-center h-full w-full my-4" data-testid="no-sequences-message">
                <tui-icon icon="@tui.file" class="h-8 w-8 text-tertiary my-4"></tui-icon>
                <p class="text-secondary">Aucun questionnaire complété</p>
                <button
                  tuiButton
                  appearance="secondary"
                  (click)="createSequence()"
                  iconEnd="@tui.plus"
                  data-testid="add-sequence-btn"
                  [disabled]="isArchived"
                >
                  Ajouter
                </button>
              </div>
            }
          </ng-template>
        } @else {
          <ng-contaiter [ngTemplateOutlet]="loading"></ng-contaiter>
        }
      } @else {
        <ng-contaiter [ngTemplateOutlet]="loading"></ng-contaiter>
      }
    </ng-container>
  } @else {
    <ng-contaiter [ngTemplateOutlet]="loading"></ng-contaiter>
  }

  <ng-template #loading>
    <pxc-loader data-testid="loading">Chargement du tableau de bord en cours ...</pxc-loader>
  </ng-template>
</div>