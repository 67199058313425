<pxc-choice-picker 
    title="Avec qui souhaitez-vous démarrer un télésuivi ?" 
>
    <pxc-card-button
        title="Avec un patient"
        icon="@tui.user"
        description="Le patient recevra par SMS un lien pour ajouter lui-même ses photos et remplir un questionnaire."
        (click)="byPatient()"
        data-testid="by-patient"
    ></pxc-card-button>
    <pxc-card-button
        title="Avec un infirmier"
        icon="@tui.heart-handshake"
        description="L’infirmier utilisera l’application Pixacare afin d’ajouter des photos du patient et remplir un questionnaire."
        (click)="byUser()"
        data-testid="by-user"
    ></pxc-card-button>
</pxc-choice-picker>