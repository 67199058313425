@if (templates$ | async; as templates) {
  <div tuiSurface="outline" class="p-1 w-full" [ngClass]="{
    'rounded-full': !templates?.length,
    'rounded-xl md:rounded-full': templates?.length
  }">
    <div class="flex max-md:flex-col md:gap-1 items-stretch w-full">

      <div class="flex items-stretch md:items-center gap-1 flex-1 max-md:flex-col">

        <div
          class="flex items-center gap-2 min-h-10 lg:w-52 flex-1 cursor-text h-full"
          (click)="focusSearch()"
        >
          <tui-icon  icon="@tui.search" class="text-tertiary ml-2 h-5"></tui-icon>
          <input #searchInput
            type="text"
            [ngModel]="search$ | async"
            (ngModelChange)="updateSearch($event)"
            data-testid="search"
            [placeholder]="searchPlaceholder"
            class="flex-1 h-full placeholder:text-tertiary placeholder:text-sm placeholder:truncate focus:outline-none"
          />
        </div>

        @if (templates?.length > 0) {
          <div
            class="flex flex-wrap max-lg:flex-nowrap gap-1 mx-1 max-md:mb-1 md:max-w-[60vw] items-center"
            data-testid="templates"
          >
            @for (template of templates; track trackByTemplate($index, template)) {
              @switch (template.type) {
                @case (FilterType.DATE_RANGE) {
                  <pxc-filter-date-range
                    [template]="template"
                    (templateChange)="updateTemplate($event)"
                    [attr.data-testid]="template.name"
                  ></pxc-filter-date-range>
                }
                @case (FilterType.SELECTOR) {
                  <pxc-filter-selector
                    [template]="template"
                    (templateChange)="updateTemplate($event)"
                    [attr.data-testid]="template.name"
                  ></pxc-filter-selector>
                }
              }
            }
            <button
              *tuiLet="(isClearable$ | async) as clearable"
              tuiIconButton
              [iconStart]="'@tui.x'"
              appearance="flat"
              size="s"
              class="ml-auto"
              (click)="clear()"
              [disabled]="!clearable"
              data-testid="clear-button"
            ></button>
          </div>
        }

        @if (actions.length > 0) {
          <button 
            tuiIconButton 
            iconStart="@tui.more-ver" 
            appearance="flat" 
            size="s" 
            data-testid="action-button"
            tuiDropdownOpen
            [tuiDropdown]="actionsDropdown"
            tuiDropdownAlign="right"
          ></button>
        }

        <ng-template #actionsDropdown>
          <pxc-menu-actions [actions]="actions"></pxc-menu-actions>
        </ng-template>
      </div>
    </div>
  </div>
}
