@if (sequences.length > 0) {
  <tui-scrollbar
    scroll-tracker
    [threshold]="{top: 700, bottom: 300}"
    class="max-h-[70vh] rounded-xl"
    [topGuard]="isPreviousPageLoading"
    [bottomGuard]="isNextPageLoading"
    (topThreshold)="this.loadPrevious.emit()"
    (bottomThreshold)="this.loadNext.emit()"
    data-testid="scroll-viewport"
  >
    <div class="flex flex-col gap-2 items-stretch">
      @if (isPreviousPageLoading) {
        <ng-container
          [ngTemplateOutlet]="spinner"
          [ngTemplateOutletContext]="{isVisible: isPreviousPageLoading}"
        ></ng-container>
      }
      @for (sequence of sequences; track trackBySequenceId(idx, sequence); let idx = $index) {
        <pxc-sequence-preview
          id="seq-{{idx}}"
          [attr.data-testid]="'seq-'+sequence.sequenceInstance.id"
          [sequence]="sequence"
          [actions]="actions"
          [patientRedirect]="patientRedirect"
          (mediaClicked)="mediaClicked($event)"
          class="animate-in fade-in slide-in-from-top-1"
          [showPatient]="showPatient"
        >
          @if (context === SequenceContext.SEQUENCE && sequence.sequenceInstance.telemonitoringId) {
            <pxc-telemonitoring-redirect-button 
              [attr.data-testid]="'telemonitoring-button'"
              [telemonitoringId]="sequence.sequenceInstance.telemonitoringId"
            ></pxc-telemonitoring-redirect-button>
          }
          @if (sequence.sequenceInstance.sadmEntityId) {
            <ng-container
              [ngTemplateOutlet]="quickLink"
              [ngTemplateOutletContext]="{
                routerLink: ['/dashboard/sadms', sequence.sequenceInstance.sadmEntityId],
                queryParams: {'tab': null, 'sequenceId': sequence.sequenceInstance.id },
                title: 'Analyse',
                testid: 'sadm-button'
              }"
            ></ng-container>
          }
          @if (
            sequence.sequenceInstance.patientFormAnswerId
            && sequence.sequenceInstance.creatorType === CreatorType.BY_PATIENT) {
            <button
              *tuiLet="sequence.sequenceInstance.riskIndicatorsCount as riskIndicatorsCount"
              tuiLink
              class="flex items-center gap-1.5"
              (click)="openPatientFormAnswer(sequence)"
              [tuiHint]="riskIndicatorsCount > 0 ? riskIndicatorHint : null"
              tuiHintAppearance="error"
            >
              Questionnaire
              @if (riskIndicatorsCount > 0) {
                <tui-chip
                  size="xs"
                  appearance="error"
                >
                  <div class="rounded-full w-2 h-2 bg-negative"></div>
                  {{ riskIndicatorsCount }}
                </tui-chip>
              }
              <ng-template #riskIndicatorHint>
                Ce questionnaire contient {{ riskIndicatorsCount }} indicateur(s) de risque
              </ng-template>
            </button>
          }
        </pxc-sequence-preview>
      }
      <ng-container
        [ngTemplateOutlet]="spinner"
        [ngTemplateOutletContext]="{isVisible: isNextPageLoading}"
      ></ng-container>
    </div>
  </tui-scrollbar>
} @else {
  <pxc-lottie-label
    animation="sequenceEmpty"
    message="Vous n'avez aucune photo"
    data-testid="no-sequence-message"
  ></pxc-lottie-label>
}

<ng-template #quickLink let-routerLink="routerLink" let-title="title" let-queryParams="queryParams" let-testid="testid">
  <a
    tuiLink
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    queryParamsHandling="merge"
    [attr.data-testid]="testid"
    class="flex items-center gap-1.5"
  >
    {{ title }}
  </a>
</ng-template>

<ng-template #spinner let-isVisible="isVisible">
  <div class="flex items-center justify-center py-2" data-testid="spinner">
    <pxc-spinner [isVisible]="isVisible"></pxc-spinner>
  </div>
</ng-template>