<div class="flex flex-col gap-2 items-start w-full">

  <pxc-back-navigation-button
    class="block mt-2"
    label="Retour à la liste"
    defaultUrl="/dashboard/telemonitorings"
    data-testid="back-button"
  ></pxc-back-navigation-button>

  @if (isTelemonitoringStateLoaded$ | async) {
    @if (telemonitoring$ | async; as telemonitoring) {
      <div class="flex max-lg:flex-col gap-8 w-full animate-in fade-in">
        <ng-container *tuiLet="(isArchived$ | async) as isArchived">
          <div class="flex flex-col mt-2 gap-6 flex-1">
            <div class="flex max-lg:flex-col gap-6 lg:items-center">
              <div>
                <ng-container [ngTemplateOutlet]="qrCodeTemplate"></ng-container>
              </div>
              <div class="flex flex-col gap-2.5">
                <div class="flex items-center gap-2">
                  <h1 tuiTitle="m" data-testid="name">
                    {{telemonitoring.telemonitoring.name}}
                  </h1>
                  @if (isArchived) {
                    <pxc-archived-badge></pxc-archived-badge>
                  }
                </div>
                @if (telemonitoring.telemonitoring.lastActivity; as lastActivity) {
                  <pxc-last-update-badge
                    [lastUpdate]="lastActivity"
                  ></pxc-last-update-badge>
                }
                <p class="text-secondary text-sm leading-snug">
                  Créé le <span class="font-medium" data-testid="creation-date">{{telemonitoring.telemonitoring.createdOn | date:'shortDate'}}</span>
                  @if (telemonitoring.createdByInstance) {
                    par <pxc-user-preview [user]="telemonitoring.createdByInstance" data-testid="author" [colored]="false"></pxc-user-preview>
                  }
                </p>
              </div>
              <pxc-action-buttons
                [entity]="telemonitoring.telemonitoring"
                [actions]="tmActions"
                [actionButtons]="actionButtonsGroups"
                data-testid="actions"
                class="lg:ml-auto"
                optionButtonAppearance="outline"
              ></pxc-action-buttons>
            </div>
            @if (telemonitoring.patient && telemonitoring.patient.isArchived) {
              <pxc-archived-notification
                title="Le patient de ce télésuivi est archivé"
                description="Vous ne pouvez plus modifier ou ajouter de données à ce patient.">
              </pxc-archived-notification>
            }
            @if (telemonitoring.telemonitoring.message) {
              <section data-testid="description" class="flex flex-col gap-2">
                <h4 tuiTitle="m">Message</h4>
                <p>{{telemonitoring.telemonitoring.message}}</p>
              </section>
            }
            <div class="flex flex-col justify-start gap-2">
              <div class="flex gap-2 max-lg:flex-wrap items-center">
                <pxc-filter-bar
                  searchPlaceholder="Rechercher une séquence par mot clé, date ou patient ..."
                  data-testid="filter-bar"
                ></pxc-filter-bar>
                @if (telemonitoring.patient) {
                  <pxc-create-button
                    (click)="addSequence()"
                    data-testid="add-sequence"
                    class="mt flex-none max-lg:w-full"
                    label="Ajouter une séquence"
                    [disabled]="telemonitoring.patient.isArchived"
                  ></pxc-create-button>
                }
              </div>
              @if (telemonitoring.sequences) {
                <div class="max-h-[70vh]">
                  <pxc-sequence-list
                    [sequences]="sequences$ | async"
                    [context]="context"
                    [actions]="sequenceActions"
                    (sequenceMediaClicked)="openGallery($event)" data-testid="sequence-list"
                  ></pxc-sequence-list>
                </div>
              } @else {
                <pxc-loader data-testid="loader" class="mx-auto my-5">
                  Chargement de votre télésuivi ...
                </pxc-loader>
              }
            </div>
          </div>
          <ng-template #qrCodeTemplate>
            <div
              *tuiLet="qrCode$ | async as qrCode"
              class="cursor-pointer rounded-lg bg-white shadow-lg w-36 h-36 overflow-hidden"
              data-testid="qr-code"
              (click)="openQrCode(telemonitoring.telemonitoring.id)"
            >
              <tui-loader [showLoader]="!qrCode" class="h-full w-full">
                @if (qrCode) {
                  <div [innerHTML]="qrCode"></div>
                }
              </tui-loader>
            </div>
          </ng-template>
        </ng-container>
      </div>
    } @else {
      <div class="flex flex-col items-center justify-center gap-4 mx-auto my-5">
        <pxc-lottie-label animation="patientEmpty" message="Oups !"></pxc-lottie-label>
        <p>Désolé, nous n'avons pas trouvé le télésuivi que vous cherchiez ...</p>
        <a tuiButton routerLink="/dashboard/telemonitorings" queryParamsHandling="merge" >Retour à la liste des télésuivis</a>
      </div>
    }
  } @else {
    <pxc-loader data-testid="loader" class="mx-auto my-5">
      Chargement de votre télésuivi ...
    </pxc-loader>
  }
</div>
